:root {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Josefin Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: 'Big Shoulders Display', SFMono-Regular, Menlo,
    Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-family-dosis: 'Dosis';

  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 15px;
  --text-lg: 17px;
  --text-xl: 18px;
  --text-2xl: 22px;
  --text-3xl: 26px;
  --text-4xl: 27px;
  --text-5xl: 28px;
  --text-5xl1: 34px;
  --text-6xl: 48px;
  --text-base: 20px;
  --text-menu: 17px;
  --text-menul: 24px;
  --text-menu2: 15px;
  --text-cart: 11px;
  --text-wcm: 24px;
  --text-wcms: 28px;
  --text-blgdesc: 15px;

  --color-darkgray: #e7e5e5;
  --color-lightgray: #ebebeb;
  --color-footermenu: #807e7e;
  --color-header: #575757;
  --color-gray: #a0aec0;
  --color-light: #e2e8f0;
  --color-lighter: #edf2f7;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-theme: #f87026;
  --color-theme1: #4b4b4b;
  --color-textgray: #686868;
  --color-choco: #f1c4aa;
  --color-deepchoco: #bf5b4b;
  --color-lightchoco: #fcf5f0;
  --color-userbox: #f8e4d5;
  --color-blue: #0f3e83;
  --color-faqodd: #e68359;
  --color-faqeven: #edb286;
  --color-dashhead: #913610;
  --color-childbox: #f8e3a6;
  --color-base: #000;
  --color-red: #f44336;
  --color-hightlightsave: #faf1d9;

  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --tracking-wide: 0.025em;
  --tracking-wider: 0.05em;
  --tracking-widest: 0.1em;

  --header-width: 1820px;
  --content-width: 1600px;
  --footer-width: 1000px;

  --star-color: #979494;
  --star-background: #ffa838;
  --rating: 0;

  --subscription-one: #eac96c;
  --subscription-two: #edb286;
  --subscription-three: #e68359;
  --subscription-four: #bf5b4b;

  --subscription-onebtn: #b79639;
  --subscription-twobtn: #9d551f;
  --subscription-threebtn: #8f3815;
  --subscription-fourbtn: #611306;

  --cartbg: #fcf5f0;
  --color-dcc: #c36455;
  --color-green: #21860d;

  --pre-color2: #39bfc2;
  --pre-color1: #f79320;
}

body {
  line-height: 1.5;
  color: var(--color-base);
  font-family: var(--font-family-dosis);
  font-size: 14px;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}

a {
  color: var(--color-base);
}

.confirm-backdrop {
  z-index: 10400;
}

.toastbutton.wishlist {
  color: var(--color-faqodd) !important;
  text-align: center;
  background-color: var(--color-white) !important;
  background-image: none !important;
  padding: 5px 30px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: unset;
  font-family: inherit;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.subscriptionbox ul li:nth-child(1) .subscriptionbutton a.item-disabled {
  color: var(--color-black) !important;
  background-color: #efefef !important;
  opacity: 0.7;
  background-image: none !important;
  cursor: not-allowed;
}
.subscriptionbox ul li:nth-child(1) .subscriptionbutton a.item-disabled:active {
  pointer-events: none;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: #000;
  margin: 5px 0;
  transition: 0.4s;
  cursor: pointer;
}

.yesopened .bar1 {
  -webkit-transform: rotate(-45deg) translate(-2px, 4px);
  transform: rotate(-45deg) translate(-2px, 4px);
  background-color: var(--color-theme);
}

.yesopened .bar2 {
  opacity: 0;
}

.yesopened .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -8px);
  transform: rotate(45deg) translate(-5px, -8px);
  background-color: var(--color-theme);
}

.onlymobile {
  display: none;
}

.mobilemenu {
  display: none;
}

.dasboardmenu {
  display: none;
}

.lessgapbotgprf {
  padding: 0 5px;
}

.listofmasrow {
  margin-right: -5px;
  margin-left: -5px;
}

.labelfrmtxt {
  font-weight: 600;
  font-size: var(--text-sm);
  margin-bottom: 3px;
  color: var(--color-base);
  display: block;
  padding-left: 5px;
}

.labelfrmtxt small {
  font-weight: bold;
  font-size: 100%;
  line-height: 0;
}

.meetambhdr small {
  color: var(--color-theme);
  font-weight: 600;
}

.ambassadorpage .sinambans {
  font-size: var(--text-md);
  font-weight: normal;
}
.ambassadorpage .sinambans p {
  margin-bottom: 10px;
}

.allfaqs.ambassadorpage ul li .sinambans li {
  margin-bottom: 0px;
  display: list-item;
  list-style: disc;
}

.allfaqs.ambassadorpage ul ul {
  padding-left: 30px;
}

.gettouchtxt {
  font-size: var(--text-md);
}

.gettouchtxt a {
  font-weight: 600;
  color: var(--color-blue);
}

.arcshbox p ~ a {
  color: var(--color-blue);
  font-weight: 600;
  margin-left: -3px;
  font-size: 90%;
}

.textreq {
  color: var(--color-theme);
  padding-left: 10px;
}

.text-center {
  text-align: center;
}
.phoneheaderrr {
  height: 22px;
  margin-right: 5px;
}
.maxxxxx li {
  min-width: max-content;
  text-align: right !important;
}

.ithassubmenu {
  position: relative;
}
.ithassubmenu ul {
  display: none !important;
  position: absolute;
  top: 106%;
  right: 0;
  background-color: #f7f7f7;
  border: 1px solid #e2e2e2;
  padding: 5px 8px !important;
  max-width: max-content;
  width: max-content !important;
}
.ithassubmenu ul li {
  margin-bottom: 10px !important;
  font-size: 14px !important;
  text-align: right !important;
}
.ithassubmenu ul li:last-child {
  margin-bottom: 0px !important;
}

.ithassubmenu:hover ul {
  display: block !important;
}

.ithassubmenu::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  cursor: pointer;
}
.ordermsg {
  color: var(--color-base);
  font-weight: 600;
}
.completemsg {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #606060;
}
.no-flex {
  display: block !important;
}
.fltrgt {
  float: right;
}

.selcectcityplanp {
  max-width: 350px;
  margin: 0 auto;
  clear: both;
  width: 100%;
  position: relative;
  z-index: 99;
}

.selcectcityplanp .toolo-select__indicator__image {
  max-height: 7px;
}

.react-date-picker button,
.react-date-picker input {
  outline: none !important;
}

.bookself-container {
  width: 85%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: var(--header-width);
}

.body-container {
  width: 90%;
  padding-right: 40px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
  max-width: var(--content-width);
}

.footer-container {
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto;
  max-width: var(--footer-width);
}

.Toastify__toast-container {
  z-index: 99999;
}

.addedcf button:disabled {
  opacity: 1;
}

.gotocheckout button:disabled {
  opacity: 0.8;
  background-color: var(--color-gray);
  color: var(--color-white);
  background-image: none;
}
.gotocheckout button:disabled:before {
  display: none;
}
.innerfldsdsh {
  margin-bottom: 7px;
  position: relative;
  clear: both;
}

/* Chrome, Safari, Edge, Opera */
.no-spin-button input::-webkit-outer-spin-button,
.no-spin-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spin-button input[type='number'] {
  -moz-appearance: textfield;
}

.newdatepicker-control {
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
  padding: 5px 10px;
  width: 100%;
}
.flatpickr-calendar {
  font-family: var(--font-family-dosis);
}

/*-----------------------   Header and Footer   ------------------------------------- */
.bookself {
  padding-top: 5px;
  position: relative;
  padding-bottom: 50px;
  border-bottom: 1px solid #e2e2e2;
}

.menulist {
  padding: 15px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
}

.menulist ul {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: contents;
}

.menulist ul li {
  list-style: none;
  font-size: var(--text-menu);
  position: relative;
  line-height: initial;
  transition: 0.2s;
  font-weight: 600;
}

.menulist ul li:nth-child(5) {
  margin-right: 0px;
}

.menulist ul li.loginlistts {
  margin-left: 15px;
  margin-right: 11px;
}

.menulist ul li a {
  color: var(--color-theme1);
  text-decoration: none;
}

.menulist ul li a:hover {
  color: var(--color-theme);
}

.menulist ul li a svg {
  width: 20px;
}

.menulist ul li a:hover svg g {
  fill: var(--color-theme);
}

.menulist ul li a.active {
  color: var(--color-theme);
}

.menulist ul li a.active svg g {
  fill: var(--color-theme);
}

.menulist ul li:last-child,
.menulist ul li:nth-last-child(2) {
  display: none;
  transition: 0.3s;
}

.bookself.sticky header.menubararea .menulist ul li:last-child,
.bookself.sticky header.menubararea .menulist ul li:nth-last-child(2) {
  display: block;
}

.menulist .logo {
  max-height: 70px;
  transition: 0.2s;
}

.hassubmenu ul {
  position: absolute;
  top: 100%;
  display: none;
  padding: 8px 0;
  z-index: 99999;
  width: fit-content;
  min-width: 150px;
}

.hassubmenu:hover ul {
  display: block;
}

.hassubmenu ul li {
  list-style: none;
  background-color: #f7f7f7;
  padding: 5px 15px;
  font-size: var(--text-sm);
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
}

.hassubmenu ul li a {
  display: block;
}

.hassubmenu ul li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #e2e2e2;
}

.hassubmenu ul li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #e2e2e2;
}

.hassubmenu ul li:nth-child(4) {
  margin-right: 0;
}

.menulist ul li.hassubmenu::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  cursor: pointer;
}

header.menubararea {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  transition: 0.3s;
  box-shadow: 0 6px 6px -6px transparent;
  bottom: 0;
  width: 100%;
  height: 56px;
}

.menulist ul li sup {
  background-color: var(--color-theme);
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 999;
  margin-left: -8px;
  font-size: var(--text-cart);
  top: -0.6em;
  font-weight: bold;
}

.sticky + .afterheader {
  margin-top: 110px;
  transition: 0.2s;
}

/*.sticky header.menubararea .logo{ max-height: 90px;}*/
.bookself header.menubararea {
  transition: 0.3s;
  border-top: 1px solid #e2e2e2;
}

.bookself.sticky header.menubararea {
  position: fixed;
  top: 0;
  animation: slide-down 0.5s;
  box-shadow: 0 6px 6px -6px #717171;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

footer {
  margin-top: 15px;
}

footer .topfootermenu {
  padding-top: 0;
  text-align: center;
}

footer hr {
  border-top: 1px solid #a3a3a3;
}

footer .topfootermenu h4 {
  color: var(--color-theme);
  font-size: var(--text-lg);
  margin-bottom: 5px;
}

footer .topfootermenu ul {
  margin: 0 auto;
  padding: 0;
}

footer .topfootermenu ul li {
  list-style: none;
  display: block;
  color: #6a6a6a;
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

footer .topfootermenu ul li a {
  color: var(--color-black);
  text-decoration: none;
}

footer .topfootermenu ul li a:hover {
  color: var(--color-theme);
}

footer .copyrightsocial {
  clear: both;
  width: 100%;
  margin: 25px 0 15px;
  overflow: hidden;
  padding: 0 30px;
}

.add-child-dialog {
  margin: 4.75rem auto;
}

.copyrighttxt {
  color: #858585;
  font-size: var(--text-sm);
  display: block;
  text-align: center;
}

.socialmediaf {
  text-align: center;
}

.socialmediaf ul {
  margin: 0 auto;
  padding: 0;
}

.socialmediaf ul li {
  list-style: none;
  display: inline-block !important;
  width: 50%;
  float: left;
  padding: 10px;
}
.socialmediaf ul li:nth-child(odd) {
  text-align: right;
}
.socialmediaf ul li:nth-child(even) {
  text-align: left;
}

.socialmediaf ul li img {
  max-width: 40px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--color-theme) !important;
  font-size: 35px !important;
}

.slick-prev,
.slick-next {
  width: 35px !important;
  height: 35px !important;
  z-index: 99;
}

.slick-next {
  right: -35px !important;
}

.slick-prev {
  left: -35px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: var(--color-theme) !important;
}

.slick-dots li {
  margin: 0 !important;
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 14px;
  font-family: Times;
  line-height: 1;
}

.Stars::before {
  content: '★★★★★';
  letter-spacing: 1px;
  background: linear-gradient(
    90deg,
    var(--star-background) var(--percent),
    var(--star-color) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pageheading {
  text-align: center;
  color: var(--color-base);
  font-size: var(--text-4xl);
  margin: 10px 0;
  background-color: #f9f9f9;
  padding: 10px;
  font-weight: bold;
}

.slick-dots {
  bottom: -40px !important;
}

.slick-slider {
  margin-bottom: 10px !important;
}

.ratingn {
  line-height: 0;
}

.page-content-zone {
  padding: 0 0;
  overflow: hidden;
}

.primarybtn {
  color: var(--color-white);
  text-align: center;
  background-image: linear-gradient(to right, #de6109, #d82c04);
  padding: 6px 30px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-md);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.searchinp__loading-indicator {
  display: none !important;
}
.searchinp__control {
  background-color: hsl(24deg 38.46% 97.45%) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.searchinp__indicator-separator ~ span {
  padding-right: 5px;
}

.primarybtn:hover:before {
  border-color: var(--color-theme);
}

.resetbtn {
  color: var(--color-theme1);
  text-align: center;
  background-color: transparent;
  padding: 0;
  min-width: auto;
  border-radius: 0px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 5px 0 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1;
}

.hvr-outline-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}

.hvr-outline-out:before {
  content: '';
  position: absolute;
  border: transparent solid 2px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
  border-radius: 4px;
}

.hvr-outline-out:hover:before,
.hvr-outline-out:focus:before,
.hvr-outline-out:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.remove {
  color: var(--color-white);
  text-align: center;
  background-color: var(--color-deepchoco);
  padding: 5px 30px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.remove svg,
.wishlist svg {
  height: 23px;
  display: inline-block;
  width: 17px;
  margin-left: 5px;
}

.remove svg g,
.wishlist svg g {
  fill: var(--color-white);
}

.remove svg {
  margin-top: -4px;
}

.profile-select-label {
  padding-top: 7px;
  padding-right: 5px;
  font-weight: 600;
}

.wishlist {
  color: var(--color-white);
  text-align: center;
  background-color: var(--color-faqodd);
  padding: 5px 30px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.remove img,
.wishlist img {
  max-height: 13px;
  margin-top: -2px;
  margin-left: 6px;
}

.remove:hover {
  background-color: var(--color-theme);
}

.wishlist:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.required {
  color: var(--color-theme);
  padding-left: 3px;
}

.imageupload {
  outline: none !important;
  font-size: 14px;
}

.fmmlbl {
  font-weight: 600;
  font-size: var(--text-sm);
}

.cprofileimage {
  text-align: center;
}

.cprofileimage img {
  max-height: 120px;
}

.ebnr {
  display: inline-block;
  text-align: center;
  border: 2px solid #e9e5e5;
  border-radius: 5px;
  cursor: pointer;
}

.addcart {
  background-color: var(--color-deepchoco);
  border: none;
  color: #fff;
  padding: 4px 10px;
  outline: none !important;
  border-radius: 4px;
  margin: 5px 2px 5px 0;
  line-height: 0;
}

.addcart:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.removewish {
  background-color: var(--color-faqodd);
  border: none;
  color: #fff;
  padding: 4px 10px;
  outline: none !important;
  border-radius: 4px;
  margin: 5px 2px 5px 0;
  line-height: 0;
}

.removewish:hover {
  background-color: var(--color-theme);
}

.eachfieldzone {
  margin-bottom: 15px;
}

.errornotif {
  display: block;
  color: var(--color-theme);
  font-size: var(--text-xs);
  font-weight: 600;
  padding-left: 8px;
}

.rlc-fldrw .form-control.error {
  border-color: var(--color-theme);
}

button:disabled {
  opacity: 0.8;
}

button:disabled.addcartlt svg g {
  fill: #868686;
}

button:disabled.addcartlt .added g {
  fill: var(--color-theme);
}

.skipfornow {
  background-color: #ebebeb;
  color: var(--color-base);
  text-align: center;
  padding: 6px 30px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-md);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 15px;
}

.skipfornow:hover {
  background-color: var(--color-red);
  color: var(--color-white);
}

.fournotfour {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorfour span {
  font-size: 200px;
  font-weight: 900;
  color: var(--color-theme);
  line-height: 0;
}

.errorfour b img {
  max-height: 200px;
}

.errorfour {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.errorpagemessage {
  font-weight: 600;
  font-size: var(--text-xl);
  color: var(--color-theme1);
}

.errorpagemessage b {
  font-weight: 800;
}

.homepagebutton {
  margin-top: 30px;
}

.logotexterropr {
  font-weight: 800;
  letter-spacing: 0.5px;
  font-size: var(--text-6xl);
  margin-bottom: 40px;
}

.logotexterropr a {
  text-decoration: none;
  color: var(--color-theme);
}

.bookcoverrest {
  position: relative;
  overflow: hidden;
}

.bookcoverrest .isfined {
  width: 120px;
  text-align: center;
  background-color: var(--color-theme);
  padding: 1px 3px;
  color: #fff;
  font-weight: 800;
  font-size: var(--text-cart);
  transform: rotate(-32deg);
  position: absolute;
  bottom: 14px;
  right: -25px;
  box-shadow: 0 0 10px #514e4e;
}

.eachbook {
  border-radius: 4px;
  position: relative;
  margin-top: 8px;
  margin: 0 1.5px;
  overflow: hidden;
}

.eachbook a {
  text-decoration: none;
}

.eachbook img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: 0.2s;
  transform: scale(1);
}

.bbokrestinfo {
  position: relative;
  transition: 0.2s;
  background-color: var(--color-lightgray);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 5px 2px;
  text-align: center;
  min-height: 40px;
  max-height: 40px;
  /* overflow: hidden; */
}

.bookname {
  color: var(--color-textgray);
  transition: 0.2s;
  font-size: var(--text-xl);
  letter-spacing: 0.3px;
  margin-bottom: 3px;
}

p.authorname {
  margin-bottom: 0;
  color: var(--color-textgray);
  font-size: var(--text-sm);
  font-weight: 500;
}

.eachbook:hover .bookname {
  color: var(--color-black);
  letter-spacing: 0.5px;
}

.filter-fldrw .form-control:disabled {
  color: #b7b4b4;
}

.quickview {
  position: absolute;
  bottom: calc(300% + 20px);
  left: calc(100% + 10px);
  width: 250px;
  z-index: 9999;
  background-color: rgb(255 255 255);
  right: 5px;
  padding: 15px 10px;
  border-radius: 8px;
  text-align: left;
  color: #000000;
  letter-spacing: 0.5px;
  font-size: 16px;
  transition: 0.5s;
  box-shadow: 0 0 15px #2e2d2d;
  opacity: 0;
  pointer-events: none;
}

.quickview:after {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 18px dashed;
  border-top: 4px solid \9;
  border-right: 18px solid transparent;
  border-left: 18px solid transparent;
  position: absolute;
  bottom: 20px;
  color: #fff;
  left: -26px;
  transform: rotate(90deg);
}

.quickview ul {
  padding: 0;
}

.quickview ul li {
  list-style: none;
  position: relative;
  padding-left: 80px;
  margin-bottom: 3px;
}

.quickview span {
  color: var(--color-theme);
  padding-right: 3px;
  position: absolute;
  left: 5px;
  top: 0;
}

.eachbook:hover .quickview {
  opacity: 1;
  bottom: 20px;
}

.alreadyread {
  position: absolute;
  right: 1px;
  top: 0px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 26px;
  background-color: var(--color-green);
  color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 4px 5px #595656;
  font-size: 12px;
}

.filterzone {
  margin: 10px 0 20px;
}

.filterfldsgroup {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
}
.resetonover {
  position: absolute;
  top: 1px;
  padding: 4px 20px;
  right: 1px;
  background-color: #fbf8f6;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}
.resetonover.needed {
  opacity: 1;
  visibility: visible;
}
.mobile-input-select {
  width: calc(100% - 100px);
  float: left;
  margin: 5px;
}

.mobile-searchbtnn {
  width: 80px;
  float: left;
  margin: 5px;
}
.mobile-searchbtnn .primarybtn {
  min-width: auto;
  padding: 8px 10px;
  font-size: 14px;
  margin-top: 0px;
  width: 100%;
}

.flex-row {
  display: flex;
}
.leftboxx {
  width: calc(100% - 200px);
}
.rightboxx {
  width: 200px;
  padding-left: 10px;
}

.filter-fldrw {
  margin-bottom: 1px;
  position: relative;
}

.filter-fldrw span {
  position: absolute;
  right: 0;
  top: 0;
  text-align: left;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.filter-fldrw span img {
  max-width: 100%;
  max-height: 20px;
}

.filter-fldrw span.forselect img {
  max-width: 100%;
  max-height: 8px;
}

.filter-fldrw select.form-control {
  -webkit-appearance: none;
  appearance: none;
}

.filter-fldrw .form-control {
  padding-right: 40px;
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
}

.one-third {
  width: calc(33.33% - 10px);
  float: left;
  margin: 5px;
}
.one-half {
  width: calc(50% - 10px);
  float: left;
  margin: 5px;
}

.twelve-full {
  width: calc(100% - 10px);
  margin: 5px;
}

.filterzone .eachfieldzone {
  margin-bottom: 0;
}

.aftersearchlist {
  margin-top: 5px;
}

.aftersearchlist .slide {
  width: 14.28%;
  float: left;
  margin: 8px 0 5px;
}

.actionbuttons {
  bottom: -150px;
  transition: 0.2s;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
}

.eachbook:hover .actionbuttons {
  bottom: 0;
  opacity: 1;
  height: 100%;
  z-index: 999;
  background-color: var(--color-white);
  border: 1px solid #e2e2e2;
}

.eachbook .addedcf.actionbuttons {
  bottom: 0;
  opacity: 1;
  height: 100%;
  z-index: 999;
  background-color: var(--color-white);
  border: 1px solid #e2e2e2;
}

.addcartlt {
  border: 1px solid #d4d4d4;
  color: #494949;
  outline: none;
  background-color: #fff;
  margin-top: 6px;
  outline: none !important;
  border-radius: 4px;
  position: relative;
  margin-right: 2px;
}

.addcartlt::after {
  content: attr(data-meaning);
  display: block;
  position: absolute;
  top: -28px;
  width: max-content;
  z-index: 9999;
  color: #fff;
  background-color: var(--color-theme1);
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 13px;
  left: -20px;
  letter-spacing: 1px;
  transition: 0.2s;
  opacity: 0;
}

.addcartlt:hover:after {
  opacity: 1;
  left: -12px;
}

.removewishlt {
  border: 1px solid #d4d4d4;
  color: #494949;
  outline: none;
  background-color: #fff;
  margin-top: 6px;
  outline: none !important;
  border-radius: 4px;
  position: relative;
}

.removewishlt::after {
  content: attr(data-meaning);
  display: block;
  position: absolute;
  top: -28px;
  width: max-content;
  z-index: 9999;
  color: #fff;
  background-color: var(--color-theme1);
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 13px;
  right: -20px;
  letter-spacing: 1px;
  transition: 0.2s;
  opacity: 0;
}

.removewishlt:hover:after {
  opacity: 1;
  right: -12px;
}

.addcartlt svg,
.removewishlt svg {
  height: 21px;
  width: 23px;
}

.addcartlt svg g,
.removewishlt svg g {
  fill: #282828;
}

.addcartlt svg.added g,
.removewishlt svg.added g {
  fill: var(--color-theme);
}

.bookcoverrest img {
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
  width: 100%;
  object-position: top;
}

.profile-select-wrapper {
  display: flex;
  justify-content: flex-end;
}

.profile-select-container {
  width: 200px;
}

.slick-track {
  margin-left: 0;
}

p.authorname {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.boollistbased .wishlist {
  margin-left: 3px;
}

.twincle {
  animation-name: blinkedtime;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blinkedtime {
  0% {
    color: var(--color-dashhead);
  }
  25% {
    color: var(--color-dashhead);
  }
  50% {
    color: var(--color-theme);
  }
  100% {
    color: var(--color-theme);
  }
}

.orderinfo {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
}
.orderinfo i {
  color: var(--color-theme);
  z-index: 9;
  position: relative;
  margin-left: 5px;
  text-shadow: 0px 0px 15px #adadad;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}
.info-order {
  z-index: 999;
  /*position: relative;*/
  background-color: #fff;
  padding: 10px;
  /*position: absolute;*/
  /*left: 30px;*/
  width: 200px;
  border-radius: 5px;
  /*top: 5px;*/
  /*transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transform: scaleY(0) translateY(15px);
  -webkit-transform: scaleY(0) translateY(15px);
  -o-transform: scaleY(0) translateY(15px);
  -ms-transform: scaleY(0) translateY(15px);
  transform: scaleY(0) translateY(15px);*/
  color: black;
  text-align: left;
}

.orders-tooltip .tooltip-inner {
  padding: 0;
  border-radius: 5px !important;
  background-color: #ffffff;
}

.info-order ul {
  margin: 0px auto;
  padding: 0px;
}
.info-order ul li {
  list-style: none;
  margin-bottom: 3px;
}
.orderinfo i:hover ~ .info-order {
  opacity: 1;
  visibility: visible;
  -moz-transform: scaleY(1) translateY(0);
  -webkit-transform: scaleY(1) translateY(0);
  -o-transform: scaleY(1) translateY(0);
  -ms-transform: scaleY(1) translateY(0);
  transform: scaleY(1) translateY(0);
}
.foruser1 .slide,
.foruser2 .slide {
  position: relative;
}

.newsarticle {
  padding: 7px 0 12px;
  overflow: hidden;
}
.newsarticle {
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.5px;
}

/*-----------------------   for Book details   ------------------------------------- */
.bookdetailsarea {
  padding: 15px 0;
  overflow: hidden;
}

.bookthumbzone {
  width: 400px;
  float: left;
  padding: 30px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.bokthinr {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  left: 0;
  top: 0;
  opacity: 0.9;
}

.bookthumbzone img {
  border-radius: 5px;
  z-index: 99;
  position: relative;
  box-shadow: 0 0 25px #000;
}

.bookthumbzone img.bkdetimg {
  max-height: 320px;
}

.bookimptdzone {
  width: calc(100% - 400px);
  padding-left: 40px;
  float: left;
}

.bookimptdzone h1 {
  color: var(--color-theme);
  font-weight: 800;
  font-size: var(--text-3xl);
}

.bookimptdzone ul {
  padding: 0;
  margin: 0;
}

.bookimptdzone ul li {
  list-style: none;
  display: block;
  font-size: var(--text-lg);
  color: var(--color-theme1);
  margin-bottom: 10px;
}

.bookimptdzone ul li span {
  font-weight: 600;
  position: relative;
  padding-right: 12px;
  margin-right: 12px;
  color: var(--color-black);
}

.bookimptdzone ul li span:after {
  display: inline-block;
  content: ':';
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1.1;
}

.bookimptdzone small {
  font-size: var(--text-xs);
  color: var(--color-dcc);
  font-weight: 600;
}

.bookimptdzone li.toollev {
  color: var(--color-dcc);
}

.bookimptdzone .ratingn {
  display: inline-block;
}

.bookimptdzone .ratingn .Stars {
  font-size: 21px;
}

.addcart-det {
  background-color: var(--color-deepchoco);
  border: none;
  color: #fff;
  padding: 12px 10px 8px;
  outline: none !important;
  border-radius: 4px;
  margin: 5px 2px 5px 0;
  line-height: 0;
  font-weight: 600;
  font-size: var(--text-xl);
  min-width: 199px;
}

.addcart-det .action-svg {
  display: inline-block;
  margin-left: 5px;
}

.addcart-det .action-svg svg {
  margin-top: -2px;
}

.addcart-det .action-svg g {
  fill: var(--color-white);
}

.addcart-det:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.wishlist-det {
  background-color: var(--color-faqodd);
  border: none;
  color: #fff;
  padding: 12px 10px 8px;
  outline: none !important;
  border-radius: 4px;
  margin: 5px 2px 5px 0;
  line-height: 0;
  font-weight: 600;
  font-size: var(--text-xl);
  min-width: 198px;
}

.wishlist-det .action-svg {
  display: inline-block;
  margin-left: 5px;
}

.wishlist-det .action-svg svg {
  margin-top: -2px;
}

.wishlist-det .action-svg g {
  fill: var(--color-white);
}

.wishlist-det:hover {
  background-color: var(--color-theme);
}

.buttonsdets {
  margin-top: 0;
}

.addcart-det img,
.wishlist-det img {
  max-height: 18px;
  margin-left: 7px;
}

.bookdescription {
  margin-top: 20px;
  font-size: var(--text-sm);
  color: var(--color-theme1);
  font-weight: 500;
  letter-spacing: 0.8px;
}

.bookdescription h3 {
  font-weight: 700;
  font-size: var(--text-xl);
  color: var(--color-theme1);
}

.bookdescription a {
  color: var(--color-theme);
  font-weight: 600;
  display: inline-block;
}

.moprebooksinfo {
  margin-top: 20px;
}

.moprebooksinfo h3 {
  font-weight: 700;
  font-size: var(--text-xl);
  color: var(--color-theme1);
}

.reviewslaggaf {
  margin-top: 20px;
}

.reviewheadings {
  margin: 0 auto;
  padding: 0;
}

.reviewheadings ul {
  margin: 0 -3px;
  padding: 0;
}

.reviewheadings ul li {
  list-style: none;
  width: calc(50% - 6px);
  margin: 0 3px;
  float: left;
  cursor: pointer;
  text-align: center;
  color: var(--color-header);
  font-weight: 600;
  font-size: var(--text-md);
  padding: 6px 20px;
  border-radius: 4px;
  position: relative;
}

.reviewheadings ul li:first-child {
  background-color: var(--subscription-one);
}

.reviewheadings ul li:last-child {
  background-color: var(--subscription-two);
}

.reviewheadings ul li.activetab:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 8px dashed;
  border-top: 4px solid \9;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
}

.reviewheadings ul li:first-child.activetab:after {
  color: var(--subscription-one);
}

.reviewheadings ul li:last-child.activetab:after {
  color: var(--subscription-two);
}

.reviewlistdet {
  margin-top: 20px;
}

.eachreview {
  margin-bottom: 25px;
  overflow: hidden;
}

.whorevthumb {
  width: 90px;
  margin-right: 15px;
  float: left;
}

.whorevthumb img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.userrevinfo {
  width: calc(100% - 105px);
  float: right;
}

.namewhorwte {
  font-weight: 700;
  color: var(--color-dcc);
  font-size: var(--text-lg);
}

.rateamount .ratingn {
  display: inline-block;
}

.rateamount .ratingn .Stars {
  font-size: 22px;
}

.rateamount span {
  color: var(--color-theme);
  margin-left: 5px;
  font-weight: 700;
  font-size: var(--text-cart);
}

.whatcommented {
  font-size: var(--text-xs);
  color: var(--color-theme1);
  font-weight: 500;
}

.nooflikes {
  font-weight: 700;
  color: var(--color-dcc);
  font-size: var(--text-xs);
  margin-top: 10px;
}

.nooflikes i {
  padding-right: 5px;
}

.readmorereview {
  font-weight: 700;
  color: var(--color-theme);
  font-size: var(--text-md);
  margin-top: 10px;
  cursor: pointer;
  margin-left: 105px;
}

.comingsoonarea {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingsoon span {
  font-size: 70px;
  font-weight: 900;
  color: var(--color-base);
  line-height: 0;
}

.paysuccess {
  font-size: 40px;
  font-weight: 900;
  color: #1a6b00;
  line-height: 1;
}

.paymessageas {
  color: var(--color-base);
  font-weight: 600;
  font-size: var(--text-2xl);
  margin-top: 15px;
}

.paymessageas span {
  display: block;
  color: var(--color-theme);
  font-weight: 600;
  font-size: var(--text-xl);
}

.overflowhide {
  overflow: hidden;
}

.reqpick {
  background-image: linear-gradient(to right, #de6109, #d82c04);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  font-size: var(--text-xs);
  font-weight: 700;
  padding: 1px 7px;
  margin-top: 5px;
}

.cancpick {
  background-color: var(--color-theme);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  font-size: var(--text-xs);
  font-weight: 700;
  padding: 1px 7px;
  margin-top: 5px;
}

.reqpick i,
.cancpick i {
  padding-right: 5px;
}

.reqpick,
.cancpick {
  background-color: var(--subscription-three);
}

/*-----------------------   for Home page   ------------------------------------- */
.eventbanner {
  padding: 0 0 5px;
}

.event-slider {
  padding: 1px 0 0;
}

.zoneheadingbox {
  overflow: hidden;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.zoneheadingbox1 {
  overflow: hidden;
  margin-bottom: 5px;
}

.sectionheader {
  color: #000000;
  font-size: var(--text-4xl);
  font-weight: bold;
  margin-bottom: 30px;
}
.text-center {
  justify-content: center;
}
.border-item {
  border: #e2e2e2 solid 1px;
  border-radius: 5px;
}
.home-most-borrowed-item {
  padding: 2px;
}
.home-most-borrowed-item .eachbook {
  margin: 0px !important;
}
.viewallbooks {
  margin-left: 15px;
  font-size: var(--text-lg);
}

.viewallbooks a {
  color: var(--color-theme);
  text-decoration: none;
  transition: 0.2s;
}

.viewallbooks a:hover {
  color: var(--color-black);
  letter-spacing: 0.5px;
}

.viewallbooks i {
  padding-left: 6px;
  font-size: 10px;
}

.event-slider .slick-dots {
  bottom: -35px !important;
}

.eventslider .slick-slide .eachevent {
  margin: 0 2px;
}

.eventslider .slick-slide:first-child .eachevent {
  margin-left: 0;
}

.eventslider .slick-slide:last-child .eachevent {
  margin-right: 0;
}

.eachevent {
  border-radius: 4px;
  overflow: hidden;
}

.eachevent a {
  text-decoration: none;
}

.eachevent img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: 0.2s;
  transform: scale(1);
}

.eachevent:hover img {
  transform: scale(1.02);
}

.eventname {
  background-color: var(--color-darkgray);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 15px;
  text-align: center;
  color: var(--color-textgray);
  overflow: hidden;
  position: relative;
  z-index: 9;
  transition: 0.2s;
  font-size: var(--text-lg);
  letter-spacing: 0.3px;
}

.eachevent:hover .eventname {
  color: var(--color-black);
}

.borrowbook-slider {
  padding: 0 0 5px;
}

.borrowedbook .slick-slide .eachbook {
  margin: 0 2px;
}

.borrowedbook .slick-slide:first-child .eachbook {
  margin-left: 0;
}

.borrowedbook .slick-slide:last-child .eachbook {
  margin-right: 0;
}

.indianauthor-slider {
  padding: 0 0 10px;
}

.indianbooks .slick-slide .eachbook {
  margin: 0 2px;
}

.indianbooks .slick-slide:first-child .eachbook {
  margin-left: 0;
}

.indianbooks .slick-slide:last-child .eachbook {
  margin-right: 0;
}

/*-----------------------   for Registration,Login,Contact page   ------------------------------------- */
.rlc-zone {
  /* overflow: hidden; */
  padding: 5px 0 15px;
}

.rlc-box {
  width: 95%;
  margin: 0 auto;
  max-width: 1050px;
  border: 2px solid var(--color-choco);
  border-radius: 4px;
  /* overflow: hidden; */
}

.asquotett {
  background-color: #efefef;
  border-radius: 5px;
  padding: 20px;
  font-style: italic;
}

.innerpattern {
  padding: 15px;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.donornoretxt {
  margin-top: 15px;
  color: var(--color-deepchoco);
  font-weight: 500;
  text-align: center;
}

.withcocobg {
  background-color: var(--color-choco);
  border-left: 1px solid #dd9a69;
}

.welcometolo {
  color: var(--color-theme1);
  font-size: var(--text-wcm);
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.welcometolo span {
  color: var(--color-theme);
  font-size: var(--text-wcms);
  padding-left: 10px;
  font-weight: 600;
}

.innerpattern form {
  width: 100%;
}

.rlc-fldrw {
  margin-bottom: 1px;
  position: relative;
  clear: both;
}

.rlc-fldrw .form-control {
  padding-right: 40px;
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
}

.rlc-fldrw span {
  position: absolute;
  right: 0;
  top: 0;
  text-align: left;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.rlc-fldrw span img {
  max-width: 100%;
  max-height: 25px;
}

.rlc-fldrw select.form-control {
  -webkit-appearance: none;
  appearance: none;
}

.rlc-fldrw select::-ms-expand {
  display: none;
}

.rlc-fldrw .nocommunity {
  color: var(--color-deepchoco);
  font-size: var(--text-sm);
  display: block;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.rlc-fldrw .nocommunity:hover {
  color: var(--color-theme);
}

.alreadyuser a.fgpass {
  color: var(--color-deepchoco);
}

.alreadyuser a.fgpass:hover {
  color: var(--color-theme);
}

.rlc-fldrw .nocommunity img {
  padding-right: 5px;
}

.rlc-fldrw .nocommunity:hover img {
  filter: grayscale(100%);
}

.rlc-fldrw .form-control:focus + span img {
  filter: grayscale(100%);
}

.rlc-submit {
  background-image: linear-gradient(to right, #de6109, #d82c04);
  color: var(--color-white);
  text-align: center;
  padding: 6px 30px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-md);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.rlc-submit:hover:before {
  border-color: var(--color-theme);
}

.alreadyuser {
  color: var(--color-deepchoco);
  font-size: var(--text-sm);
  display: block;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.alreadyuser img {
  margin-right: 5px;
}

.alreadyuser a {
  color: var(--color-theme);
  padding-left: 5px;
}

.rlc-info {
  text-align: center;
}

.rlc-info h3.spareheading {
  color: var(--color-theme1);
  font-size: var(--text-3xl);
}

.rlc-info .sparedesc {
  font-size: var(--text-sm);
  margin-bottom: 20px;
}

.onloadrefresh {
  text-align: center;
  min-height: 230px;
}

.onloadrefresh img {
  max-height: 230px;
}

.rlc-fldrw span.fortxtare {
  top: 10px;
  align-items: flex-start;
}

#loadmascots {
  display: inline-block;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.socialbuttonlogin {
  margin-top: 25px;
}

.socialbuttonlogin a {
  margin: 5px;
  border-radius: 4px;
  border: 2px solid #bcb0b0;
  padding: 8px 10px;
  text-decoration: none;
  font-size: var(--text-md);
  font-weight: 600;
}

.socialbuttonlogin a img {
  margin-left: 10px;
  max-height: 21px;
}

.socialbuttonlogin a.withgmail {
  color: var(--color-theme1);
}

.socialbuttonlogin a.widthfacebook {
  color: var(--color-blue);
}

.socialbuttonlogin a:hover {
  border-color: var(--color-deepchoco);
}

.contactsocials {
  text-align: center;
  width: 100%;
  margin-top: 15px;
}

.contactsocials p {
  margin-bottom: 2px;
  color: var(--color-black);
  font-size: var(--text-xl);
  font-weight: 600;
}

.contactsocials ul {
  margin: 0 auto;
  padding: 0;
}

.contactsocials ul li {
  list-style: none;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.contactsocials ul li img {
  max-width: 35px;
}

.contactnonts {
  margin: 0 auto;
  padding: 0;
  margin-bottom: 20px;
}

.contactnonts ul {
  margin: 0 auto;
  padding: 0;
}

.contactnonts ul li {
  list-style: none;
  display: inline-block;
  margin: 7px 5px;
}

.contactnonts ul li a {
  text-decoration: none;
  color: var(--color-black);
  font-weight: 600;
  font-size: var(--text-sm);
  display: flex;
  align-content: center;
}

.contactnonts ul li img {
  margin-right: 5px;
  max-height: 25px;
}

.contactnonts ul li a:hover {
  color: var(--color-theme);
}

.contactmas img {
  max-height: 260px;
}

/*------------------------------------------   faq page   ------------------------------------- */

.allfaqs {
  margin: 0 auto;
  padding: 0;
  margin-top: 20px;
  font-family: var(--font-family-dosis);
}

.allfaqs ul {
  margin: 0 auto;
  padding: 0;
}

.allfaqs ul li {
  list-style: none;
  display: block;
  margin-bottom: 20px;
  text-align: left;
  overflow: hidden;
  border-radius: 15px;
}

.allfaqs ul li .questinhead {
  padding: 12px 50px 12px 40px;
  color: var(--pre-color1);
  cursor: pointer;
  position: relative;
  font-size: var(--text-lg);
  background-color: var(--color-white);
  font-weight: 600;
}

/* .allfaqs ul li:nth-child(even) .questinhead {
  background-color: var(--color-faqeven);
} */

.allfaqs ul li .anserzone {
  display: none;
  padding: 20px;
  margin-top: 0;
  font-weight: 500;
  color: var(--color-black);
  font-size: var(--text-sm);
  line-height: 1.6;
  letter-spacing: 0.8px;
  background-color: #ffffff;
  position: relative;
}

.allfaqs ul li .anserzone:before {
  content: '';
  height: 1.5px;
  width: calc(100% - 30px);
  position: absolute;
  top: 0;
  left: 15px;
  background-color: var(--pre-color1);
}

.allfaqs ul li .questinhead:before {
  content: 'Q';
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  background-color: var(--pre-color2);
  margin-right: 15px;
  font-size: 11px;
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 10px);
  left: 10px;
  font-family: none;
  font-size: 14px;
}

.allfaqs ul li .questinhead:after {
  content: '+';
  display: inline-block;
  position: absolute;
  right: 10px;
  font-size: 30px;
  font-weight: 500;
  top: 0;
  color: #8b8b8b;
}

.allfaqs ul li.activer .questinhead:after {
  content: '-';
}

.allfaqs ul li.activer .anserzone {
  display: block;
}

/*------------------------------------------   Subscription page   ------------------------------------- */
.subscriptionbox {
  margin: 20px 0 25px;
}

.subscriptionbox ul {
  margin: 0 -5px;
  padding: 0;
}

.subscriptionbox ul li {
  list-style: none;
  width: 25%;
  float: left;
  padding: 5px;
}

.subscriptionbox ul li .innerbox {
  border-radius: 4px;
  padding: 20px 10px 100px;
  text-align: center;
  position: relative;
}

.subscriptionbox ul li:nth-child(1) .innerbox {
  background-color: var(--subscription-one);
  color: var(--color-theme1);
}

.subscriptionbox ul li:nth-child(2) .innerbox {
  background-color: var(--subscription-two);
  color: var(--color-white);
}

.subscriptionbox ul li:nth-child(3) .innerbox {
  background-color: var(--subscription-three);
  color: var(--color-white);
}

.subscriptionbox ul li:nth-child(4) .innerbox {
  background-color: var(--subscription-four);
  color: var(--color-white);
}

.subscriptionbox ul li:nth-child(1) li:before {
  background-image: url(../src/assets/images/tick-b.png);
  width: 13px !important;
  height: 13px;
  max-width: unset !important;
}

.subscriptionbox ul li:nth-child(2) li:before,
.subscriptionbox ul li:nth-child(3) li:before,
.subscriptionbox ul li:nth-child(4) li:before {
  background-image: url(../src/assets/images/tick-w.png);
  width: 13px !important;
  height: 13px;
  max-width: unset !important;
}

.no-message {
  color: var(--color-theme);
}

.subscriptionbox ul li .imgrep {
  padding-bottom: 15px;
  min-height: 65px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.subscriptionbox ul li:nth-child(1) .imgrep img {
  max-width: 30px;
}

.subscriptionbox ul li:nth-child(2) .imgrep img {
  max-width: 52px;
}

.subscriptionbox ul li:nth-child(3) .imgrep img {
  max-width: 40px;
}

.subscriptionbox ul li:nth-child(4) .imgrep img {
  max-width: 40px;
}

.subscriptionbox ul li .imgrep img {
  max-width: 60px;
  max-height: 100%;
}

.subscriptionbox ul li .price {
  font-weight: 800;
  font-size: var(--text-5xl);
  margin-bottom: 0;
}

.subscriptionbox ul li .price sup {
  font-size: 60%;
}

.subscriptionbox ul li .price sub {
  font-size: 40%;
  bottom: 0;
  position: relative;
  padding-left: 5px;
}

.subscriptionbox ul li .price sub:before {
  position: absolute;
  bottom: 3px;
  left: 0;
  line-height: 1;
  font-weight: 800;
  content: '/';
  font-size: 70%;
}

.subscriptionbox ul li .subslength {
  font-weight: 800;
  font-size: var(--text-2xl);
  margin-bottom: 15px;
}

.subscriptionbox ul li .subsfacilities {
  text-align: center;
  overflow: hidden;
  min-height: 164px;
}

.subscriptionbox ul li .subsfacilities ul {
  padding: 0;
  margin: 0;
}

.subscriptionbox ul li .subsfacilities ul li {
  list-style: none;
  display: block;
  margin-bottom: 5px;
  font-size: var(--text-lg);
  position: relative;
  width: 100%;
  padding: 0 0 0 20px;
  font-weight: 600;
}

.subscriptionbox ul li .subsfacilities ul li:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(50% - 8px);
  padding: 0;
  width: auto;
  font-size: 0;
  background-color: transparent;
  color: inherit;
  max-width: fit-content;
  background-size: 105%;
}
.subscriptionbox ul li .subsfacilities ul li.isnot:before {
  content: '✖';
  background-image: none;
  font-size: 13px;
}

.subscriptionbox ul li .subscriptionbutton {
  position: absolute;
  bottom: 20px;
  z-index: 999;
  width: 100%;
  left: 0;
}

.subscriptionbox ul li .subscriptionbutton a {
  text-decoration: none;
  font-size: var(--text-md);
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 25px;
  border-radius: 5px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  min-width: 60%;
  display: inline-block;
}

.subscriptionbox ul li:nth-child(1) .subscriptionbutton a {
  color: var(--color-black);
  background-color: var(--subscription-onebtn);
}

.subscriptionbox ul li:nth-child(2) .subscriptionbutton a {
  color: var(--color-white);
  background-color: var(--subscription-twobtn);
}

.subscriptionbox ul li:nth-child(3) .subscriptionbutton a {
  color: var(--color-white);
  background-color: var(--subscription-threebtn);
}

.subscriptionbox ul li:nth-child(4) .subscriptionbutton a {
  color: var(--color-white);
  background-color: var(--subscription-fourbtn);
}

.subscriptionbox ul li .subscriptionbutton a:hover {
  color: var(--color-white);
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.onetimepay {
  color: var(--color-theme);
  font-size: var(--text-md);
  margin-top: 20px;
}

.paysubdesc {
  color: var(--color-theme1);
  font-size: var(--text-md);
}

.subscriptionbox ul li:last-child {
  position: relative;
}

.subscriptionbox ul li:last-child:after {
  display: inline-block;
  width: 0;
  content: '';
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 11px dashed;
  border-top: 4px solid \9;
  border-right: 9px solid transparent;
  border-left: 4px solid transparent;
  position: absolute;
  right: 28px;
  top: 2px;
  color: var(--color-red);
}

.subscriptionbox ul li:nth-child(1) .innerbox .price {
  color: var(--color-red);
}

.subscriptionbox ul li:last-child:before {
  content: 'Most Popular';
  display: inline-block;
  position: absolute;
  top: -39px;
  background-color: var(--color-red);
  color: var(--color-white);
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  right: 12px;
  max-width: 70px;
  text-align: center;
  line-height: 1.1;
  font-weight: 700;
}

.subscriptionbox ul li li:last-child:after {
  display: none;
}

small.notapplicable {
  margin-top: -5px;
  margin-bottom: 10px;
  display: block;
  font-size: 95%;
  font-style: italic;
  font-weight: 500;
}

/*------------------------------------------   Cart page   ------------------------------------- */
.cartbg {
  background-color: var(--cartbg);
  padding: 30px 15px;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-left: 1px solid #dd9a69;
}

.cartbox {
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
  border: 2px solid var(--color-choco);
  border-radius: 4px;
  overflow: hidden;
}

.cartbg h5 {
  width: 100%;
  text-align: center;
  color: var(--color-dcc);
  font-size: var(--text-2xl);
  margin-bottom: 20px;
  font-weight: 600;
}

.cartbg .rcdinfo {
  width: 100%;
  text-align: center;
  color: var(--color-dcc);
  font-size: var(--text-md);
  margin-bottom: 20px;
  font-weight: 600;
}

.cartbg .rcdinfo span {
  font-size: var(--text-xl);
  display: block;
  color: var(--color-theme1);
}

.totalborrowed {
  width: 80%;
  margin: 0 10%;
  font-size: var(--text-2xl);
  color: var(--color-theme);
  font-weight: 600;
  margin-bottom: 20px;
  background-color: var(--color-white);
  padding: 7px 5px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #dd9a69;
  margin-top: 15px;
}

.foruserinfo {
  padding: 7px 15px;
  background-color: var(--cartbg);
  margin-bottom: 4px;
  border-bottom: 1px solid #dd9a69;
  color: var(--color-theme1);
  font-weight: 600;
  font-size: var(--text-xl);
  border-top: 1px solid #dd9a69;
  margin-top: -1px;
}

.boollistbased {
  margin-bottom: 5px;
  overflow: hidden;
  width: 100%;
}

.perbookinfo {
  display: flex;
  width: 100%;
  position: relative;
  padding: 10px 20px 10px 15px;
}

.perbookinfo .imagebook {
  width: 110px;
  padding-right: 15px;
}

.perbookinfo .imagebook img {
  border-radius: 2px;
}

.perbookinfo .restbookinfo {
  width: calc(100% - 110px);
  padding-top: 12px;
}

.perbookinfo .restbookinfo h5 {
  color: var(--color-theme1);
  font-size: var(--text-lg);
}

.perbookinfo:after {
  width: 95%;
  content: '';
  height: 1.5px;
  background-color: #efe1d7;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.perbookinfo:last-child:after {
  display: none;
}

.perbookinfo:before {
  content: attr(data-number);
  display: inline-block;
  color: var(--color-theme);
  margin-right: 10px;
  font-weight: 800;
  font-size: var(--text-sm);
}

.checkoutgistinfo {
  width: 100%;
}

.gotocheckout {
  text-align: center;
}

/*------------------------------------------  Checkout page   ------------------------------------- */
.checkoutbox {
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
  border: 2px solid var(--color-choco);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px 15px;
  text-align: center;
}

.checkoutsuccess {
  width: 100%;
  text-align: center;
  color: var(--color-theme);
  font-size: var(--text-4xl);
  margin-bottom: 5px;
  font-weight: 600;
}

.dateinfocheckout {
  font-size: var(--text-lg);
  display: block;
  color: var(--color-theme1);
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}

.dateinfocheckout span {
  color: var(--color-dcc);
}

.reqbooksl {
  width: 100%;
  text-align: center;
  color: var(--color-theme1);
  font-size: var(--text-md);
  margin-bottom: 10px;
  font-weight: 600;
}

.booklist {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.booklist ul {
  margin: 0 auto;
  padding: 0;
}

.booklist ul li {
  list-style: none;
  display: inline-block;
}

.booklist ul li img {
  max-height: 80px;
  border-radius: 5px;
  margin: 0 5px;
}

.booklist ul li span {
  font-weight: bold;
  color: #848484;
  font-size: var(--text-xs);
  cursor: pointer;
}

/*------------------------------------------ For Common Dashboard related pages  ------------------------------------- */
.dashboardarea {
  margin: 15px 0;
  overflow: hidden;
}

.dashboardbox {
  width: 100%;
  margin: 0 auto;
  border: 2px solid var(--color-choco);
  border-radius: 4px;
  overflow: hidden;
}

.dashsidebar {
  padding-left: 15px;
  width: 250px;
}

.sidebarinner {
  background-color: var(--color-lightchoco);
  height: 100%;
}

.dashcontentrest {
  padding-right: 15px;
  width: calc(100% - 250px);
}

.dashcontentinner {
  border-left: 1px solid #dd9a69;
  padding: 15px;
  height: 100%;
}

.whologged {
  background-color: var(--color-userbox);
  padding: 15px;
  border-bottom: 1px solid var(--color-choco);
}

.shortcutmenus {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notiemail {
  display: flex;
  align-items: center;
}

.notiemail a {
  margin-left: 10px;
  position: relative;
}

.notiemail a sup {
  background-color: var(--color-theme);
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 999;
  margin-left: -5px;
  font-size: var(--text-cart);
  top: -0.6em;
  font-weight: bold;
}

.profileimgzone {
  text-align: center;
  margin: 20px 0 5px;
  height: 100px;
}

.profileimgbox {
  width: 100px;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1.5px solid #d0c0b3;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profileimgbox img {
  max-height: 70px;
}

.profileimgbox span {
  position: absolute;
  bottom: -4px;
  right: -5px;
}

.loggedusername {
  font-size: var(--text-md);
  color: var(--color-theme);
  font-weight: 800;
  margin-top: 0;
  text-align: center;
}

.sidemenulist {
  margin: 20px 0;
}

.sidemenulist h5 {
  padding: 10px 15px;
  font-size: var(--text-sm);
  color: var(--color-dashhead);
  margin-bottom: 0;
  font-weight: 800;
}

.sidemenulist ul {
  margin: 0 auto;
  padding: 0 15px;
}

.sidemenulist ul li {
  list-style: none;
  z-index: 999;
  margin-bottom: 5px;
  position: relative;
  border-radius: 5px !important;
}

.sidemenulist ul li a {
  color: var(--color-theme1);
  font-size: var(--text-sm);
  text-decoration: none;
  font-weight: 600;
  display: block;
  padding: 7px 15px;
  background-color: transparent;
  transition-duration: 0.3s;
  border-radius: 5px !important;
}

.sidemenulist ul li a i {
  padding-right: 10px;
  color: var(--color-dashhead);
  width: 30px;
  transition-duration: 0.3s;
}

.sidemenulist ul li a svg {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.sidemenulist ul li a svg g {
  fill: var(--color-dashhead);
}

.sidemenulist ul li a:hover svg g {
  fill: var(--color-theme);
}

.sidemenulist ul li:hover a i {
  color: var(--color-theme);
}

.sidemenulist ul li:hover {
  background-color: var(--color-white);
}

.sidemenulist ul li.active a i {
  color: var(--color-theme);
}

.sidemenulist ul li a.active i {
  color: var(--color-theme);
}

.sidemenulist ul li a.active {
  background-color: var(--color-white);
}

.smspace {
  margin: 0 -4px;
}

.smspace .col-md-4 {
  padding: 0 4px;
}

.singhighbox {
  border-radius: 6px;
  overflow: hidden;
  height: 100%;
  padding: 20px 15px;
  text-align: center;
}

.bookgist {
  background-color: var(--subscription-one);
  color: var(--color-theme1);
}

.donationgist {
  background-color: var(--subscription-two);
  color: var(--color-white);
}

.plangist {
  background-color: var(--subscription-three);
  color: var(--color-white);
}

.dashiconbox {
  text-align: center;
}

.dashiconbox svg {
  height: 30px;
  width: 100%;
}

.bookgist svg g {
  fill-opacity: 0.3;
  fill: #000;
}

.donationgist svg g {
  fill-opacity: 0.7;
  fill: #fff;
}

.plangist svg g {
  fill-opacity: 0.6;
  fill: #fff;
}

.singhighbox h4 {
  font-size: var(--text-xl);
  font-weight: 800;
  margin-top: 15px;
}

.singhighbox a h4 {
  color: var(--color-base);
}

.singhighbox a {
  text-decoration: none;
}

.singhighbox .infolist {
  font-weight: 600;
  margin-top: 5px;
}

.bookgist .infolist span {
  margin-right: 3px;
  font-weight: 800;
  font-size: var(--text-md);
  color: var(--color-black);
  background-color: var(--subscription-onebtn);
  padding: 2px 10px;
  border-radius: 4px;
}

.donationgist .infolist span {
  margin-right: 3px;
  font-weight: 800;
  font-size: var(--text-md);
  color: var(--color-white);
  background-color: var(--subscription-twobtn);
  padding: 2px 10px;
  border-radius: 4px;
}

.plangist .infolist a {
  font-weight: 800;
  font-size: var(--text-sm);
  color: var(--color-white);
  background-color: var(--subscription-threebtn);
  padding: 5px 20px;
  border-radius: 4px;
  margin-top: 7px;
  text-decoration: none;
  display: inline-block;
}

.plangist .infolist a:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.plangist .infolist small {
  font-size: 80%;
  font-weight: 800;
  color: #000;
}

.dash-sectheading {
  margin-top: 15px;
  font-weight: 700;
  font-size: var(--text-xl);
  color: var(--color-theme1);
}

.dash-foruser {
  padding: 5px 15px;
  background-color: var(--cartbg);
  margin-bottom: 10px;
  color: var(--color-theme1);
  font-weight: 600;
  font-size: var(--text-xl);
  border-radius: 4px;
}

.dash-foruser span {
  padding-right: 7px;
}

.dash-foruser span img {
  max-height: 30px;
  padding: 2px 5px;
  background-color: #fd33047a;
  margin-top: -4px;
}

.isfined {
  width: 120px;
  text-align: center;
  background-color: var(--color-theme);
  padding: 1px 3px;
  color: #fff;
  font-weight: 800;
  font-size: var(--text-cart);
  transform: rotate(-32deg);
  position: absolute;
  bottom: 14px;
  right: -25px;
  box-shadow: 0 0 10px #514e4e;
}

.toolo-nominates {
  position: absolute;
  color: #ffdf01;
  padding: 4px 6px;
  top: 10px;
  left: 10px;
  z-index: 9;
  text-transform: capitalize;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 18px;
  box-shadow: 0 4px 5px #3b3a3aab;
  background: linear-gradient(135deg, #189e96 0%, #189e96 0.01%, #2e8c43 100%);
  transition: width 5s ease;
  overflow: hidden;
}

.toolo-nominates::after {
  position: absolute;
  display: inline-block;
  content: '';
  top: -25%;
  left: -25%;
  height: 150%;
  width: 150%;
  background: #ffc40030;
  transform: translateX(-100%) 5s;
  animation: pagOutX 5s forwards infinite;
  z-index: 10;
  rotate: 45deg;
}
.toolo-nominates i {
  position: relative;
  z-index: 11;
}

@keyframes pagInX {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes pagOutX {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.unavaabledet {
  width: 120px;
  text-align: center;
  background-color: var(--color-theme);
  padding: 1px 3px;
  color: #fff;
  font-weight: 800;
  font-size: var(--text-cart);
  transform: rotate(-32deg);
  position: absolute;
  bottom: 14px;
  right: -25px;
  box-shadow: 0 0 10px #514e4e;
  z-index: 9;
}

.alreadyread {
  z-index: 9;
}

.overhover {
  position: absolute;
  top: -100px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  transition-duration: 0.3s;
  opacity: 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.renew {
  background-color: var(--color-theme);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  font-size: var(--text-xs);
  font-weight: 700;
  padding: 1px 7px;
}

.renew i {
  padding-right: 5px;
}

.review {
  background-color: var(--color-theme);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  font-size: var(--text-xs);
  font-weight: 700;
  padding: 1px 7px;
}

.review i {
  padding-right: 5px;
  font-size: 80%;
}

.deachbook:hover .overhover {
  top: 0;
  opacity: 1;
}

.pickup {
  color: var(--color-white);
  text-align: center;
  background-color: var(--color-deepchoco);
  padding: 5px 30px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 0 0 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.canpickup {
  color: var(--color-white);
  text-align: center;
  background-color: var(--color-faqodd);
  padding: 5px 30px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-xs);
  outline: none !important;
  margin: 0 0 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pickup:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.canpickup:hover {
  background-color: var(--color-theme);
}

.deachbook {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.deachbook a {
  text-decoration: none;
}

.deachbook img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: 0.2s;
  transform: scale(1);
  max-height: 170px;
  min-height: 170px;
  object-fit: cover;
  width: 100%;
  object-position: top;
}

.bbokrestinfo {
  position: relative;
  transition: 0.2s;
  background-color: var(--color-lightgray);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 5px 2px;
  text-align: center;
}

.bookname {
  color: var(--color-textgray);
  transition: 0.2s;
  font-size: var(--text-xl);
  letter-spacing: 0.3px;
  margin-bottom: 3px;
}

p.authorname {
  margin-bottom: 0;
  color: var(--color-textgray);
  font-size: var(--text-sm);
}

.deachbook:hover .bookname {
  color: var(--color-black);
  letter-spacing: 0.5px;
}

.partbyborroewed {
  margin-top: 15px;
  overflow: hidden;
  margin: 7px -2px 0;
}

.partbyborroewed .slide {
  width: calc(16.6% - 4px);
  float: left;
  margin: 0 2px 5px;
}

.reqdel-infodt {
  background-color: var(--subscription-one);
  color: var(--color-theme1);
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
}

.requesteddate {
  padding: 8px 15px 6px;
  width: 50%;
  float: left;
  font-weight: 600;
  font-size: var(--text-sm);
}

.requesteddate img {
  margin-right: 10px;
  max-height: 23px;
  margin-top: -5px;
}

.requesteddate b {
  color: var(--color-dashhead);
  font-weight: 500;
}

.returweneddate {
  padding: 8px 15px 6px;
  width: 50%;
  float: left;
  border-left: 1px solid #efddaa;
  font-weight: 600;
  font-size: var(--text-sm);
}

.returweneddate img {
  margin-right: 10px;
  max-height: 23px;
  margin-top: -5px;
}

.returweneddate b {
  color: var(--color-dashhead);
  font-weight: 500;
}

.eachcheckoutbase {
  margin-bottom: 10px;
  clear: both;
}

.accountzone form {
  width: 100%;
}

.dash-fldrw {
  margin-bottom: 10px;
  position: relative;
  clear: both;
  margin-top: 8px;
}

.dash-fldrw .form-control {
  padding-right: 40px;
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
}

.dash-fldrw span {
  position: absolute;
  right: 0;
  top: 0;
  text-align: left;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.dash-fldrw span img {
  max-width: 100%;
  max-height: 25px;
}

.dash-fldrw select.form-control {
  -webkit-appearance: none;
  appearance: none;
}

.dash-fldrw select::-ms-expand {
  display: none;
}

.dash-fldrw .form-control:focus + span img {
  filter: grayscale(100%);
}

.dash-submit {
  background-image: linear-gradient(to right, #de6109, #d82c04);
  color: var(--color-white);
  text-align: center;
  padding: 6px 30px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-md);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.dash-submit:hover:before {
  border-color: var(--color-theme);
}

.dash-fldrw span.fortxtare {
  top: 10px;
  align-items: flex-start;
}

.dash-fldrw .form-control:disabled,
.dash-fldrw .form-control[readonly] {
  border: 1px solid #afafaf;
  background-color: #e7e7e7;
}

.dash-fldrw textarea.form-control {
  min-height: 101px;
}

.ambasdrnewsslider .slide {
  padding: 3px;
}
.eachambnws {
  padding: 0px;
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}

/*------------------------------------------  Dashboard page   ------------------------------------- */
.foruser1 .slick-slide .deachbook,
.foruser2 .slick-slide .deachbook {
  margin: 0 2px;
}

.foruser1 .slick-prev,
.foruser2 .slick-prev {
  left: 2px !important;
}

.foruser1 .slick-next,
.foruser2 .slick-next {
  right: 2px !important;
}

.foruser1 .deachbook,
.foruser2 .deachbook {
  overflow: hidden;
}

.foruser1.slick-slider,
.foruser2.slick-slider {
  margin-bottom: 5px !important;
}

/*------------------------------------------  account page   ------------------------------------- */
.proimgbx {
  width: 45px;
  margin-right: 10px;
  float: left;
  padding: 3px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin-bottom: 10px;
}

.subprofiles .btn.btn-primary {
  background-color: var(--color-deepchoco);
  padding: 2px 10px;
  height: auto;
  font-size: var(--text-sm);
  border: none;
  margin-left: 10px;
}

.subprofiles .btn.btn-primary:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.restuploadinfo {
  width: calc(100% - 55px);
  float: left;
  padding-top: 10px;
  margin-bottom: 10px;
}

.orders-tooltip {
  opacity: 1;
}

.dash-fldrw:before {
  content: attr(data-text);
  display: block;
  position: absolute;
  top: -25px;
  font-size: var(--text-xs);
  color: var(--color-dashhead);
  font-weight: 700;
  padding-left: 8px;
}

.subprofiles {
  margin-top: 15px;
}

.childfbox {
  margin: 5px -3px 0;
  display: flex;
}

.eachchild {
  width: calc(50% - 6px);
  float: left;
  margin: 0 3px;
  padding: 15px;
  background-color: var(--color-childbox);
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  border: 1px solid #d8d8d8;
}

.childdp {
  width: 80px;
  margin-right: 20px;
  float: left;
}

.childdp img {
  border-radius: 4px;
}

.childinfo {
  width: calc(100% - 100px);
}

.childinfo h6 {
  color: var(--color-theme);
  font-weight: 600;
  font-size: var(--text-md);
  margin-bottom: 3px;
}

.anotherchinfo {
  color: var(--color-theme1);
  font-weight: 600;
  font-size: var(--text-sm);
  margin-bottom: 3px;
}

.buttonchedit {
  background-color: var(--color-deepchoco);
  border: none;
  color: #fff;
  padding: 3px 20px;
  outline: none !important;
  border-radius: 4px;
  margin: 5px 2px 0 0;
  line-height: 0;
}

.buttonchedit:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

/*------------------------------------------  Loading    ------------------------------------- */
.preloader-wrap {
  background-color: #fdfaf6;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 99999;
}

.preloader-wrap-animation .circle {
  -webkit-animation: circle-animation-small 4.2s infinite;
  animation: circle-animation-small 4.2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.preloader-wrap .circle {
  background-color: var(--color-theme);
  border-radius: 50%;
}

.preloader-wrap .circle,
.preloader-wrap .logo-wrap {
  left: 50%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 900;
  color: #fff;
  font-size: 80px;
}

@-webkit-keyframes circle-animation-small {
  0% {
    height: 0;
    width: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    height: 300rem;
    width: 300rem;
    opacity: 0;
  }
}

@keyframes circle-animation-small {
  0% {
    height: 0;
    width: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    height: 300rem;
    width: 300rem;
    opacity: 0;
  }
}

.refundplcybox {
  padding: 25px;
  background-color: #fbfbfb;
  border: 1px solid var(--color-theme);
  text-align: center;
}

.refundplcybox h5 {
  font-weight: 700;
  font-size: var(--text-lg);
}

.refundplcybox p {
  font-weight: 500;
  font-size: var(--text-md);
}

.partbytpcnt {
  margin-top: 15px;
}

.partbytpcnt h6 {
  font-size: var(--text-lg);
  font-weight: 700;
}

.partbytpcnt p {
  font-size: var(--text-sm);
  line-height: 1.6;
}

.partbytpcnt a {
  color: var(--color-theme);
}

.partbytpcnt small {
  font-weight: 600;
}

.bloglist {
  margin-top: 15px;
  font-family: var(--font-family-dosis);
}

.blogdetdesc {
  color: var(--color-textgray);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
  word-break: break-word;
}

.blogdetdesc p {
  padding-top: 10px;
  margin-bottom: 0;
}

.blogdetailshed {
  color: var(--color-base);
  font-size: var(--text-2xl);
  margin-bottom: 7px;
  font-weight: 600;
}

.publushedanddt {
  color: var(--color-textgray);
  font-size: var(--text-sm);
  margin-bottom: 2px;
  font-weight: 600;
}

.publushedanddt span {
  color: var(--color-theme);
}

.publushedanddt span:first-child {
  margin-right: 8px;
}

.socialicobsh {
  margin-bottom: 5px;
}

.socialicobsh ul {
  margin: 0 auto;
  padding: 0;
}

.socialicobsh ul li {
  list-style: none;
  margin-right: 5px;
  display: inline-block;
}

.socialicobsh ul li img {
  max-height: 25px;
}

.missionvisonbox {
  margin-top: 30px;
  font-family: var(--font-family-dosis);
}

.innermsvbox {
  display: flex;
  align-items: center;
}

.contsbox {
  width: calc(100% - 180px);
  height: fit-content;
}

.samemsvimg {
  width: 100px;
}

.contsbox {
  width: calc(100% - 180px);
  height: fit-content;
}

.profileabout {
  width: 200px;
}

.arcshbox {
  width: calc(100% - 280px);
  height: fit-content;
}

.contsbox h6 {
  color: var(--color-deepchoco);
  font-size: var(--text-xl);
  font-weight: 600;
}

.contsbox h6 span {
  display: block;
  font-size: var(--text-sm);
  color: #7e7e7e;
}

.contsbox h6 small {
  margin-left: 8px;
  font-weight: 600;
  font-size: 60%;
}

.contsbox p {
  margin-bottom: 0;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.arcshbox h6 {
  color: var(--color-deepchoco);
  font-size: var(--text-xl);
  font-weight: 600;
}

.arcshbox h6 span {
  display: block;
  font-size: var(--text-sm);
  color: #7e7e7e;
}

.arcshbox h6 small {
  margin-left: 8px;
  font-weight: 600;
  font-size: 60%;
}

.arcshbox p {
  margin-bottom: 0;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.missionbox {
  margin-right: 80px;
  background-color: #f9efd3;
  padding: 20px 20px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.missionbox:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #f9efd3;
  display: block;
  position: absolute;
  bottom: -7px;
  left: 250px;
}

.vissonbox {
  margin-left: 80px;
  background-color: #f1f1f1;
  padding: 20px 20px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.vissonbox:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -17px;
  left: 70px;
}

.vissonbox:before {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #f1f1f1;
  display: block;
  position: absolute;
  bottom: -8px;
  right: 370px;
}

.missionbox1 {
  margin-right: 80px;
  background-color: #f9efd3;
  padding: 20px 20px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.missionbox1:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #f9efd3;
  display: block;
  position: absolute;
  bottom: -7px;
  left: 350px;
}

.vissonbox1 {
  margin-left: 80px;
  background-color: #f1f1f1;
  padding: 20px 20px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.vissonbox1:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -17px;
  left: 70px;
}

.missionbox1:before {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -16px;
  right: 70px;
}

.abouttoolobox {
  margin: 30px 0;
  position: relative;
}

.inneraboutus {
  background-color: #fcf4ee;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  font-family: var(--font-family-dosis);
}

.inneraboutus h6 {
  color: var(--color-deepchoco);
  font-size: var(--text-2xl);
  font-weight: 700;
}

.inneraboutus p {
  margin-bottom: 15px;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.inneraboutus p:last-child {
  margin-bottom: 0;
}

.aboutlftconts {
  width: 40%;
  text-align: left;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.aboutlftconts li {
  margin-bottom: 5px;
}

.aboutlftconts li:last-child {
  margin-bottom: 0;
}

.aboutrgtconts {
  width: 40%;
  text-align: right;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.aboutmdlimg {
  text-align: center;
  width: 20%;
  padding: 0 20px;
}

.abouttoolobox .row {
  margin: 30px 0;
  align-items: center;
}

.abouttoolobox:before {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -14px;
  right: 370px;
}

.abouttoolobox:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fcf4ee;
  display: block;
  position: absolute;
  bottom: -8px;
  left: 170px;
}

.whattodo {
  margin: 30px 0;
  position: relative;
  font-family: var(--font-family-dosis);
}

.innerwhatto {
  background-color: #faebc1;
  padding: 25px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
}

.innerwhatto h6 {
  color: var(--color-deepchoco);
  font-size: var(--text-2xl);
  font-weight: 700;
}

.innerwhatto p {
  margin-bottom: 15px;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.8px;
}

.innerwhatto p:last-child {
  margin-bottom: 0;
}

.innerwhatto:before {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: -16px;
  left: 170px;
}

.innerwhatto:after {
  content: '';
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #faebc1;
  display: block;
  position: absolute;
  bottom: -7px;
  right: 350px;
}

.comingsoommsg {
  color: var(--color-base);
  font-weight: 600;
  font-size: var(--text-md);
}

.ratingn {
  opacity: 0;
  font-size: 0;
}

.imageblog {
  margin-top: 10px;
  padding-bottom: 10px;
}

.dasboardmenuonlymob {
  display: none;
}

.bookself .dashsidebar {
  display: none;
}

/* ----------------------------------- Recommandation ------------------------------ */
.recommanedlist {
  margin-top: 5px;
  overflow: hidden;
  font-family: var(--font-family-dosis);
}

.askrevrecpge {
  font-family: var(--font-family-dosis);
  text-align: center;
  font-weight: 500;
  font-size: var(--text-md);
}

.askrevrecpge a {
  color: var(--color-theme);
}

.eachrecommand:first-child {
  padding-top: 70px;
}

.eachrecommand:last-child {
  padding-bottom: 30px;
}

.eachrecommand {
  padding-top: 0;
  clear: both;
  position: relative;
  padding-bottom: 110px;
}

.bookbgboxo {
  position: relative;
  background-color: #f9efd3;
  padding: 7px 20px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bookleftimagewact {
  width: 140px;
  margin-top: -70px;
  position: absolute;
  height: 160px;
  background-color: #f6e1dc;
  padding: 2px;
  border-radius: 5px;
  z-index: 999;
}

.bookleftimagewact a {
  position: relative;
  overflow: hidden;
  display: block;
}

.bookleftimagewact img {
  width: 100%;
  min-height: 156px;
  max-height: 156px;
  object-fit: cover;
}

.bookrightinted {
  padding-left: 170px;
  position: relative;
  z-index: 99;
}

.bookrightinted h4 {
  position: absolute;
  top: -50px;
  font-size: var(--text-xl);
  color: #913610;
  font-weight: 600;
}

.bookrightinted h4:hover a {
  color: var(--color-theme);
}

.bookrightinted h4 a {
  color: #913610;
  text-decoration: none;
}

.bookrightinted ul {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}

.bookrightinted ul li {
  padding-right: 40px;
  font-size: var(--text-md);
  font-weight: 500;
}

.headingreby {
  padding-left: 230px;
  margin-top: 5px;
  font-size: var(--text-lg);
  color: #913610;
  font-weight: 600;
}

.eachreconnnamdcond {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: flex-end;
}

.reconderinfo {
  width: 160px;
  /* background-image: url(../src/assets/images/recinfobg.png); */
  background-size: 100%;
  /* height: 160px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.recomandersaid {
  width: calc(100% - 190px);
  padding: 15px;
  border: 2px solid #f6e1dc;
  margin-left: 30px;
  font-size: var(--text-md);
  position: relative;
  padding-left: 30px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
}

.recomandersaid:before {
  width: 36px;
  background-image: url(../src/assets/images/qute.png);
  content: '';
  display: block;
  background-size: 100%;
  height: 52px;
  position: absolute;
  left: -25px;
  top: calc(50% - 20px);
}

.recuserimg {
  max-height: 60px;
}

.recuserimg img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
}

.reconderinfo h4 {
  text-align: center;
  color: var(--color-theme);
  font-weight: 600;
  font-size: var(--text-md);
  margin-bottom: 0;
}

.reconderinfo h4 span {
  display: block;
  font-size: 80%;
  color: #913610;
}

.recscl {
  padding: 0;
  margin: 0;
}

.recscl ul {
  margin: 0 auto;
  padding: 0;
}

.recscl ul li {
  list-style: none;
  display: inline-block;
  padding: 5px 5px 0 5px;
  line-height: 0;
}

.recomandersaid p {
  margin-top: 10px;
  margin-bottom: 0;
}

.actbuttonsrec {
  text-align: center;
  margin-top: 7px;
}

.alertcartdisabled {
  color: red;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}

/*------------------------------ Blog list --------------------------*/
.bloglist .col-md-4 {
  margin-bottom: 20px;
}

.blogboxech {
  overflow: hidden;
  background-color: #fdf6f0;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border: 1px solid #e2e2e2;
}
.blogboxech:hover {
  box-shadow: 0px 0px 15px #c7c7c7;
}

.imageblogs {
  text-align: center;
  transition: all 0.6s;
  overflow: hidden;
}

.imageblogs img {
  max-height: 200px;
  min-height: 200px;
  min-width: 200px;
  object-fit: cover;
  width: 100%;
  object-position: top;
  transition: all 0.6s;
}

.blogrestinfo {
  position: relative;
  transition: 0.2s;
  padding: 20px 10px 20px;
  text-align: center;
  transition: all 0.6s;
}

.blogtitle {
  color: var(--color-base);
  font-size: var(--text-md);
  font-weight: 600;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.6s;
}

.blogtitle a {
  color: var(--color-base);
  text-decoration: none;
  transition: all 0.6s;
}

.blogtitle a:hover {
  color: var(--color-theme);
}

.blogdescr {
  color: var(--color-textgray);
  font-size: var(--text-md);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.6s;
}

.bloglist .col-md-4:nth-child(even) .blogboxech {
  background-color: #f9f9f9;
}

.blogboxech:hover {
  background-color: #39bfc2 !important;
  border-color: #39bfc2 !important;
}
.blogboxech:hover .blogrestinfo .blogtitle a {
  color: var(--color-white) !important;
}
.blogboxech:hover .blogrestinfo .blogdescr {
  color: var(--color-white) !important;
}

.blogboxech:hover .blogrestinfo .readmoreblg a {
  color: var(--color-white) !important;
}

.blogboxech:hover .blogrestinfo .readmoreblg a svg path {
  fill: var(--color-white) !important;
}
.blogboxech {
  transition: all 0.3s ease-in-out;
}
.blogboxech:hover {
  transform: translateY(-5px);
}

.readmoreblg {
  margin-top: 5px;
  font-size: var(--text-sm);
  font-weight: 600;
}

.readmoreblg a {
  color: var(--color-base);
  text-decoration: none;
  transition: all 0.6s;
}

.readmoreblg a svg {
  margin-left: 5px;
  height: 10px;
  transition: all 0.6s;
}
.readmoreblg a svg path {
  transition: all 0.6s;
}

.hasrowgap {
  row-gap: 25px;
}

.isVideo div {
  width: 100% !important;
  height: fit-content !important;
}
.isVideo div.react-player__preview {
  width: 100% !important;
  height: 100% !important;
}
.isVideo div.react-player__preview div {
  width: auto !important;
}

.isVideo:after,
.isVideo:before {
  display: none !important;
}

.whatsay.isVideo {
  padding: 10px 10px 5px;
}

.why-every-schho-bg {
  background: linear-gradient(180deg, #edf4f5 0%, rgba(237, 244, 245, 0) 100%);
}

/*------------------------------ Ambassador --------------------------*/
.becomestipeambs {
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #f1f1f1;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.becomestipeambs h1 {
  color: var(--color-deepchoco);
  font-size: var(--text-xl);
  font-weight: 600;
}

.commonambs {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.infocomnamb {
  width: 60%;
  font-size: var(--text-lg);
  font-weight: normal;
}

.infocomnamb b {
  font-size: var(--text-2xl);
  color: var(--color-deepchoco);
  font-weight: 600;
}

.infocomnamb p {
  margin-top: 10px;
}

.imgcomamb {
  width: 40%;
  padding-left: 15px;
}

.imgcomamb img {
  border-radius: 7px;
}

.benifitsamb {
  margin-top: 40px;
}

.benifitsamb .col-md-3 {
  margin: 20px 0;
}

.innerbenf {
  height: 100%;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px 10px 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px #e2e2e2;
}

.innerbenf h4 {
  margin: 5px 0;
  color: var(--color-deepchoco);
  font-size: var(--text-xl);
  font-weight: 600;
}

.innerbenf p {
  font-size: var(--text-md);
  margin-top: 10px;
}

.ambspage .arcshbox h6 span {
  margin-top: 6px;
}

.ambspage .profileabout img {
  border-radius: 100%;
}

.ambspage .missionbox1:before,
.ambspage .vissonbox1:before,
.ambspage .missionbox1:after,
.ambspage .vissonbox1:after {
  display: none;
}

.ambspage .missionbox1 {
  margin-right: 30px;
}

.ambspage .vissonbox1 {
  margin-left: 30px;
}

.ambspage .arcshbox {
  width: calc(100% - 230px);
}

.ambsaddorslist {
  margin-top: 30px;
}

.ambfaqs {
  margin-top: 45px;
}

.ambfaqs h3 {
  text-align: left;
  margin-bottom: 15px;
}

.ambfaqs .singlefaqamb {
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e2e2e2;
}

.ambfaqs .singlefaqamb:last-child {
  border: none;
}

.ambfaqs .singlefaqamb h4 {
  color: var(--color-deepchoco);
  font-size: var(--text-xl);
  font-weight: 600;
  margin-bottom: 10px;
}

.ambfaqs .singlefaqamb .sinambans {
  font-size: var(--text-md);
}

.ambfaqs .singlefaqamb .sinambans p {
  margin-top: 10px;
}
.sinambans a {
  font-weight: 600;
  color: var(--color-blue);
}

.buttonapplybtn {
  padding: 25px 0;
}

/*------------------------------ Ambassador apply --------------------------*/
.aambinfo {
  text-align: center;
  margin-bottom: 10px;
  font-size: var(--text-md);
}

.ambsdr .rlc-fldrw .form-control {
  padding-right: 10px;
}

.ambsdr .rlc-fldrw select.form-control {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}

.newdatepicker-control span {
  position: unset;
  display: unset;
  width: unset;
  text-align: unset;
  pointer-events: all;
}

.rlc-fldrw .newdatepicker-control .react-date-picker__wrapper {
  padding-right: 5px;
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
  padding: 5px 10px;
}

.rlc-fldrw .react-date-picker {
  width: 100%;
}

.ambsdr,
.abmpage {
  font-family: var(--font-family-dosis);
}

/*------------------------------ Events --------------------------*/

.singleupcome {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 15px #e2e2e2;
  padding: 15px;
  border-radius: 5px;
}

.upcomeimglft {
  width: 250px;
  margin-right: 25px;
}

.upcomeinforight {
  width: calc(100% - 275px);
}

.upcomeinforight h4 {
  color: var(--color-deepchoco);
}

p.timeupent {
  font-size: var(--text-sm);
  font-weight: 600;
  margin-bottom: 5px;
}

.descrupevnt {
  font-size: 15px;
  margin-bottom: 5px;
  text-align: justify;
}

.descrupevnt p {
  margin-bottom: 0px;
}

/* .descrupevnt br{
  display: none;
} */
.homepagedescrb {
  padding: 5px 0px 5px;
}
.homepagedescrb h1 {
  color: var(--color-header);
  font-size: var(--text-2xl);
  font-weight: 600;
}
.homepagedescrb small {
  font-weight: 600;
}
.homepagedescrb p {
  margin-bottom: 7px;
  color: var(--color-base);
  font-size: var(--text-md);
  letter-spacing: 0.5px;
}

.homepagedescrb p a {
  color: var(--color-blue);
  font-weight: 500;
}

.agedefine {
  font-size: var(--text-sm);
  color: var(--color-faqodd);
  font-weight: 600;
  margin-bottom: 5px;
}

.feesdefine {
  font-size: var(--text-sm);
  font-weight: 600;
}

.feesdefine a {
  display: block;
  margin-top: 5px;
}

.feesdefine a button {
  color: var(--color-white);
  text-align: center;
  background-image: linear-gradient(to right, #de6109, #d82c04);
  padding: 4px 20px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-sm);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}

.singleupcome:nth-child(odd) {
  flex-direction: row-reverse;
}

.singleupcome:nth-child(odd) .upcomeimglft {
  margin-left: 25px;
  margin-right: 0;
}

.singleupcome:last-child {
  margin-bottom: 20px;
}

.allpastevent .singlepastevnt {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  height: 100%;
  background-color: #fff;
}

.singlepastevnt .upcomeimglft {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
}

.singlepastevnt .upcomeimglft img {
  width: 100%;
}

.singlepastevnt .upcomeinforight {
  width: 100%;
}

.allpastevent .col-md-4 {
  margin-bottom: 15px;
}

/*------------------------------ Testimonial --------------------------*/

.testimonials .slick-track {
  display: flex !important;
  flex-wrap: wrap;
}

.testimonials .slick-slide {
  height: inherit !important;
}

.testimonials .slick-slide > div {
  height: 100%;
}

.slide {
  height: 100%;
}

.eachtestimonbox {
  padding: 10px;
  height: 100%;
}
/* .testimonials .slide {
  display: flex;
  align-items: center;
} */

.whatsay {
  padding: 15px 25px 2px;
  background-color: #fff;
  box-shadow: 0 0 10px #d4d4d4;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
  font-size: var(--text-md);
  color: var(--color-theme1);
  min-height: calc(100% - 75px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: justify;
}

.whatsay:after {
  content: '”';
  display: block;
  font-size: 55px;
  line-height: 0;
  margin-top: 30px;
  color: var(--color-faqodd);
  font-family: fantasy;
  text-align: right;
  width: 100%;
}

.whatsay:before {
  content: '“';
  display: block;
  font-size: 55px;
  line-height: 0;
  margin-top: 17px;
  color: var(--color-faqodd);
  font-family: fantasy;
}

.tmluserinfo {
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.tmluserinfo:after {
  display: inline-block;
  width: 0;
  content: '';
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 11px dashed;
  border-top: 4px solid \9;
  border-right: 9px solid transparent;
  border-left: 4px solid transparent;
  position: absolute;
  left: 139px;
  top: -14px;
  color: var(--subscription-three);
}

.tmluserinfo img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}

.tmluserinfo h4 {
  margin-left: 10px;
  font-size: var(--text-lg);
  color: var(--color-theme);
  width: calc(100% - 70px);
}

.tmluserinfo h4 small {
  color: var(--color-base);
}

.tmluserinfo h4 .locationfrom {
  display: block;
  color: var(--color-base);
  font-size: var(--text-sm);
  margin-top: 5px;
  line-height: 0.9;
}

.eachtestimonbox {
  font-family: 'Dosis', sans-serif !important;
}

.eachfinebox {
  display: flex;
  width: 100%;
  position: relative;
  padding: 10px 20px 10px 15px;
}

.eachfinebox:after {
  width: 95%;
  content: '';
  height: 1.5px;
  background-color: #efe1d7;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.eachfinebox:last-child:after {
  display: none;
}

.imageblock {
  width: 110px;
  padding-right: 15px;
}

.fineinfo {
  width: calc(100% - 110px);
  padding-top: 0;
}

.fineinfo h4 {
  margin-top: 0;
  color: var(--color-theme1);
  font-size: var(--text-xl);
  margin-bottom: 10px;
}

.nextinfo {
  margin-bottom: 7px;
  font-size: var(--text-md);
}

.nextinfo b {
  padding-right: 10px;
}

.dashcontentrest .alert {
  font-weight: bold;
}

.contactcommunity {
  margin: 20px 0;
}
.contactcommunity .col-md-3 {
  margin: 10px 0;
}

.splsup {
  font-size: 40% !important;
  top: -25px;
}

.addsup {
  font-size: 50%;
  top: -0.8em;
}

.comnutyinner {
  background-color: var(--color-white);
  padding: 15px;
  text-align: center;
  box-shadow: 0px 0px 15px #e2e2e2;
  border-radius: 5px;
  font-family: var(--font-family-dosis);
  height: 100%;
}
.comnutyinner b {
  margin-bottom: 8px;
  display: block;
  color: var(--color-theme);
  font-size: var(--text-sm);
}
.comnutyinner p {
  margin-bottom: 8px;
  font-weight: 600;
}
.comnutyinner p img {
  padding-right: 5px;
  max-height: 20px;
}
.comnutyinner p.ambnme {
  display: block;
  font-weight: bold;
}
.comnutyinner p a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reffriend {
  background-color: var(--color-white);
  color: var(--color-theme1);
  border: 2px solid var(--subscription-two);
  padding: 20px 15px 10px;
}

.reffriend svg g {
  fill: var(--subscription-two);
}

.reffriend ul {
  margin: 0px auto;
  padding: 0px;
}

.reffriend ul li {
  list-style: none;
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 600;
  font-size: var(--text-sm);
  margin-bottom: 10px;
}

.reffriend ul li b {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: var(--subscription-two);
  margin-right: 15px;
  color: var(--color-white);
  border-radius: 4px;
  font-weight: 600;
}

.reffriend ul li span {
  width: calc(100% - 50px);
  text-align: left;
}

.heightbg1 {
  background-color: var(--subscription-two);
  height: 1px;
  width: calc(100% + 40px);
  margin-left: -20px;
}

.copyreferral {
  padding: 10px 0px 0px;
  font-weight: bold;
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyreferral span:nth-child(2) {
  width: 50px;
}

.copyreferral span:nth-child(2) b {
  margin-left: 8px;
  cursor: pointer;
}

.copyreferral span:nth-child(2) svg,
.copyreferral span:nth-child(2) svg g {
  height: 16px;
  color: var(--color-theme1);
  fill: var(--color-theme1);
}

.refreward {
  background-color: var(--color-white);
  color: var(--color-theme1);
  border: 2px solid var(--subscription-three);
}

.refreward svg g {
  fill: var(--subscription-three);
}

.refreward ul {
  margin: 0px auto;
  padding: 0px;
}

.refreward ul li {
  list-style: none;
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 600;
  font-size: var(--text-sm);
  margin-bottom: 10px;
}

.refreward ul li b {
  width: 60px;
  height: 35px;
  line-height: 35px;
  background-color: var(--subscription-three);
  margin-right: 15px;
  color: var(--color-white);
  border-radius: 4px;
  font-weight: 600;
}

.refreward ul li:nth-child(2) b {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.refreward ul li span {
  width: calc(100% - 75px);
  text-align: left;
}

.noteoffertxt {
  font-size: var(--text-xs);
  color: var(--subscription-three);
  text-align: left;
  position: relative;
  padding-left: 6px;
}

.noteoffertxt:before {
  content: '*';
  display: inline-block;
  position: absolute;
  left: 0;
  font-weight: 600;
  font-size: var(--text-md);
}

.referrallist {
  border-top: 1px solid #e2e2e2;
  padding-top: 10px;
}

.refindivisual {
  margin-bottom: 15px;
}

.refindivisual .refuserdet {
  padding-left: 15px;
}
.refindivisual .refuserdet h6 {
  color: var(--subscription-three);
  font-weight: 600;
  margin-bottom: 0px;
  font-size: var(--text-sm);
}
.refindivisual .refuserdet p {
  font-weight: 500;
  margin-bottom: 0px;
  color: var(--color-theme1);
  font-size: var(--text-xs);
}
.refindivisual .refuserstatus {
  padding-right: 15px;
}

.refindivisual .refuserstatus ul {
  margin: 0px auto;
  padding: 0px;
}

.refindivisual .refuserstatus ul li {
  list-style: none;
  display: inline-block;
  margin-left: 15px;
}

.refindivisual .refuserstatus ul li span {
  display: block;
  text-align: center;
  font-size: var(--text-xs);
  font-weight: 500;
}
.refindivisual .refuserstatus ul li span i {
  color: #8b8b8b;
}

.refindivisual .refuserstatus ul li:nth-child(1).active span i {
  color: var(--color-theme);
}

.refindivisual .refuserstatus ul li:nth-child(2).active span i {
  color: #f6b900;
}

.refindivisual .refuserstatus ul li:nth-child(3).active span i {
  color: #40c711;
}

.height15 {
  height: 15px;
}

.referralCodeBlock {
  color: rgb(143, 56, 21);
  background: #edb185;
  padding: 5px;
  border-radius: 5px;
}
.sidemenulist ul li sup {
  margin-left: 5px;
  background-color: #f00;
  font-weight: 600;
  color: var(--color-white);
  padding: 2px 8px;
  border-radius: 4px;
  animation: blinker 1.5s linear infinite;
  font-size: 90%;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.earnedref {
  background-color: var(--color-white);
  color: var(--color-theme1);
  border: 2px solid var(--subscription-threebtn);
}
.earnedref h4 {
  color: var(--color-base);
}
.earnedref ul li:nth-child(2) b {
  background-color: var(--subscription-three) !important;
  background-image: none;
}

.earnedref ul li:nth-child(3) span {
  width: calc(100% - 145px);
  text-align: left;
}

.earnedref svg g,
.earnedref svg path {
  fill: var(--subscription-threebtn);
}

.earnedref ul li small {
  cursor: pointer;
  background-color: var(--subscription-threebtn);
  padding: 5px 4px;
  color: var(--color-white);
  font-size: 12px;
  border-radius: 4px;
  position: relative;
}

.earnedref ul li small:before {
  content: '';
  position: absolute;
  border: transparent solid 2px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
  border-radius: 4px;
}

.earnedref ul li small:hover:before {
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-color: var(--color-theme);
}

.earnedref ul li small.disabled {
  background-image: none;
  background-color: #a1a1a1;
}

.earnedref ul li small.disabled::before {
  border-color: transparent !important;
}

.earnedref ul li small:after {
  content: attr(data-text);
  color: var(--color-theme);
  display: block;
  position: absolute;
  width: max-content;
  right: 0;
  bottom: -22px;
  opacity: 0;
  font-weight: 600;
}
.earnedref ul li small:hover:after {
  opacity: 1;
}

.share-social i {
  margin-right: 15px;
  cursor: pointer;
  font-size: 22px;
}

.share-social button {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.share-social button:nth-child(1) i {
  color: #293a97;
}

.share-social button:nth-child(2) i {
  color: #2fb942;
}

.share-social button:nth-child(3) i {
  color: #818181;
}
.onemonthplanitis .innerbox .price {
  color: var(--color-white) !important;
}
.onlymobiledisplaymsg {
  display: none;
  font-weight: 600;
  color: #ff5b01;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 64px;
  left: 0;
}
.onlymobiledisplaymsg i {
  padding-right: 5px;
}
.storyambbbb .slide {
  padding: 4px 8px;
}
.highlightsavebox {
  background-color: var(--color-hightlightsave);
  padding: 5px 15px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background-image: url(assets/images/celebrate.gif);
  background-size: cover;
  background-repeat: repeat;
  background-repeat: repeat;
  position: fixed;
  right: -150%;
  max-width: 760px;
  width: 90%;
  z-index: 9999999;
  top: 20px;
  box-shadow: 0px 0px 15px #e2e2e2;
  transition: 0.6s;
  border: 2px solid #e68359;
}
.highlightsavebox.isActive {
  right: 10px;
}
.highlightsavebox .leftbox {
  padding-right: 15px;
  padding-left: 15px;
  width: 120px;
  display: flex;
  align-items: center;
}

.highlightsavebox .rightimagebx {
  padding-right: 15px;
  padding-left: 15px;
  width: 150px;
  text-align: center;
}
.leftbox .mascotimg {
  width: 90px;
  float: left;
}

.highlightsavebox .contentblock {
  width: calc(100% - 270px);
  float: left;
  text-align: center;
  padding: 0px 15px;
}

.highlightsavebox .contentblock h3 {
  font-weight: 700;
  color: var(--subscription-twobtn);
  font-size: 19px;
}
.highlightsavebox .contentblock h3 b {
  font-size: var(--text-2xl);
  color: var(--color-theme);
}
.highlightsavebox .contentblock small {
  font-size: 13px;
}
.highlightsavebox .contentblock p {
  margin-bottom: 0px;
  font-size: var(--text-md);
  font-weight: 500;
}
.highlightsavebox .clodethis {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
}
.marginTop-5 {
  margin-top: 5px;
}
.marginTop-10 {
  margin-top: 15px;
  line-height: 1.4;
  font-weight: normal;
}

.top-logo-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}
.top-logo-left {
  max-width: 150px;
}
.top-logo-left.has__brand__logo {
  max-width: 300px;
}
.top-logo-left.has__brand__logo a {
  display: flex;
  align-items: center;
  padding-bottom: 7.5px;
}
.top-logo-left img {
  max-height: 65px;
  vertical-align: inherit;
}
.pl-20 {
  padding-left: 20px;
}
.pl-c5 {
  padding-left: 5px;
}
.top-menu-right {
  max-width: calc(100% - 150px);
}
.top-menu-right.no__brand__right {
  max-width: calc(100% - 300px);
}
.top-menu-right ul {
  margin: 0px auto;
  padding: 0px;
  padding-left: 5px;
  justify-content: end;
  display: flex;
}
.top-menu-right ul li {
  list-style: none;
  font-size: var(--text-menu);
  position: relative;
  line-height: initial;
  transition: 0.2s;
  font-weight: 600;
  margin-left: 20px;
  color: var(--color-theme1);
}
.top-menu-right ul li span {
  color: var(--color-theme1);
  text-align: left;
}
.top-menu-right ul li:nth-child(1) a {
  font-size: 95%;
}
.top-menu-right ul li.headerlogin a {
  padding: 3px 15px 4px;
  border: 1px solid var(--color-theme);
  border-radius: 5px;
  background-color: var(--color-theme);
  color: var(--color-white) !important;
}
.top-menu-right ul li.headerlogin:hover a {
  background-color: var(--pre-color2);
  border-color: var(--pre-color2);
}
.top-menu-right ul li a {
  color: var(--color-theme1);
  text-decoration: none;
}
.top-menu-right ul li a svg {
  width: 20px;
}
.top-menu-right ul li a sup {
  background-color: var(--color-theme);
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 999;
  margin-left: -8px;
  font-size: var(--text-cart);
  top: -0.6em;
  font-weight: bold;
}
.top-menu-right ul li:hover a,
.top-menu-right ul li a.active {
  color: var(--color-theme);
}
.top-menu-right ul li a img {
  margin-right: 5px;
  height: 22px;
}

.banner {
  background: url(../src/assets/images/banner1.webp) no-repeat top center;
  position: relative;
  padding: 100px 0;
  min-height: calc(100vh - 132px);
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50% 30%;
}

.banner:after {
  content: '';
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.herotext {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  max-width: 700px;
  color: var(--color-white);
  /* margin: 0px auto; */
  float: right;
}
.herotext h1 {
  font-size: 54px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 25px;
}
.herotext h4 {
  font-size: var(--text-2xl);
  line-height: 1.7;
  font-weight: normal;
}

.mt-100 {
  margin-top: 100px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-20 {
  margin-top: 20px;
}
.prebuttons {
  border-radius: 20px;
  padding: 9px 40px;
}
.prebuttons svg {
  margin-left: 10px;
  display: inline-block;
  height: 12px;
}
.one-border-top {
  border-top: 1px solid #e2e2e2;
}
.pre-banner h1 {
  font-size: 70px;
  text-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  font-weight: 800;
  line-height: 1.3;
}
.pre-banner h1 b {
  background-color: var(--pre-color2);
  padding: 0px 15px 12px;
  border-radius: 15px;
}

.aboutimg {
  border-radius: 10px;
  overflow: hidden;
}

.about_block {
  padding: 30px 0;
}
.about_block h5 {
  font-size: var(--text-2xl);
  color: var(--color-theme);
  font-weight: 500;
  margin-bottom: 20px;
}
.about_block h3 {
  font-size: var(--text-3xl);
  font-weight: 700;
  color: var(--color-base);
  letter-spacing: 1px;
  line-height: 1.4;
}
.about_block p {
  margin: 10px 0px 20px;
  font-size: var(--text-xl);
  text-align: justify;
}

.how-it-works {
  padding: 40px 0 10px;
  overflow: hidden;
}
.works-steps {
  position: relative;
}
.works-steps:before {
  width: 100%;
  height: 1px;
  border: 1px dashed var(--color-theme);
  content: '';
  display: block;
  position: absolute;
  top: 50%;
}
.onestepbox {
  text-align: center;
  padding: 15px;
  box-shadow: 0px 0px 15px #e2e2e2;
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
}
.onestepbox .step-image {
  max-width: 100px;
  margin: 0px auto;
  margin-bottom: 10px;
}
.onestepbox h4 {
  font-size: var(--text-2xl);
  font-weight: 600;
  color: var(--color-deepchoco);
  margin-bottom: 7px;
}
.onestepbox p {
  font-size: var(--text-xl);
}

.button-for-more {
  padding: 20px 0;
  text-align: center;
}

.infosliders .row {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.blue-normal {
  color: var(--color-white);
  text-align: center;
  padding: 6px 30px;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: none;
  border: none !important;
  font-weight: 600;
  font-size: var(--text-md);
  outline: none !important;
  margin: 5px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--color-theme);
}
.blue-normal:hover {
  background-image: linear-gradient(to right, #de6109, #d82c04);
}

.toolo-data-gist {
  margin-top: 15px;
}
.toolo-data-gist .col-md-4 .inner-gist-data {
  padding: 65px 15px 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  position: relative;
}
.toolo-data-gist .col-md-4 .inner-gist-data p {
  font-size: 22px;
  margin-bottom: 0px;
}
.toolo-data-gist .col-md-4 .inner-gist-data b {
  font-size: 40px;
}

.toolo-data-gist .col-md-4:nth-child(1) .inner-gist-data {
  background-color: var(--subscription-one);
  color: var(--color-theme1);
  border-radius: 4px;
}
.toolo-data-gist .col-md-4:nth-child(2) .inner-gist-data {
  background-color: var(--subscription-two);
  color: var(--color-theme1);
  border-radius: 4px;
}
.toolo-data-gist .col-md-4:nth-child(3) .inner-gist-data {
  background-color: var(--subscription-four);
  color: var(--color-white);
  border-radius: 4px;
}
.iconzone {
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 60px;

  border-radius: 50%;
  margin-top: -65px;
  position: absolute;
  top: -30px;
  top: 50px;
  border: 3px solid;
}
.iconzone svg {
  width: 35px;
}
.toolo-data-gist .col-md-4:nth-child(1) .inner-gist-data .iconzone {
  background-color: #f8e09c;
  border-color: var(--subscription-one);
}
.toolo-data-gist .col-md-4:nth-child(2) .inner-gist-data .iconzone {
  background-color: #f6c199;
  border-color: var(--subscription-two);
}
.toolo-data-gist .col-md-4:nth-child(3) .inner-gist-data .iconzone {
  background-color: #d56f5f;
  border-color: var(--subscription-four);
}

.home-past-event .allpastevent .sectionheader {
  display: none;
}

.home-past-event {
  background-color: #e4e4e4;
}

.aboveeventcta {
  padding: 15px;
  font-size: 22px;
  font-weight: 600;
}
.aboveeventcta span {
  width: 25px;
  position: relative;
  display: inline-block;
  font-size: 10px;
  text-align: center;
}
.sourcename a {
  color: var(--color-theme);
}

.citylist {
  padding: 20px 0;
  overflow: hidden;
}
.completecitylist {
  margin: 15px 0 0px;
  width: 100%;
  overflow: hidden;
  /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
}

.homepagemstborrowed .border-item {
  background-color: #fff;
}
.pt-40 {
  padding-top: 40px;
}
.contact-box-home {
  padding: 15px 25px;
  background-color: #f3f3f3;
  border-radius: 10px;
}
.cityname {
  font-size: var(--text-xl);
  color: var(--color-base);
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  width: 25%;
  float: left;
}
.cityname span {
  margin-left: 10px;
  font-size: 70%;
  color: var(--color-white);
  font-weight: 500;
  padding: 2px 10px;
  background-color: var(--color-theme);
  border-radius: 5px;
}
.cityname ul {
  padding: 0px;
  margin: 0px;
  margin-bottom: 7px;
}
.cityname ul li {
  list-style: none;
  display: block;
  font-size: 85%;
  margin-top: 7px;
}
.top-menu-right ul ul {
  display: none;
}
.top-menu-right ul ul li {
  font-size: var(--text-sm);
  margin-left: 0px;
}
.top-menu-right li:hover ul li a {
  color: var(--color-theme1);
}
.top-menu-right li ul li:hover a {
  color: var(--color-theme);
}
.top-menu-right ul li.hassubmenu::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  cursor: pointer;
}
h4.category-title {
  display: block;
  margin: 5px 0;
  text-align: center;
  font-size: var(--text-xl);
  color: var(--color-base);
  font-weight: 500;
}
.home-past-event .descrupevnt {
  overflow: hidden;
  height: 47px;
}
.home-blogs {
  overflow: hidden;
}
.ml-10 {
  margin-left: 10px;
}
.pt-0 {
  padding-top: 0px;
}
.mb-20 {
  margin-bottom: 20px;
}
.alter-home-section {
  background-color: #e4e4e4;
}

/* ------------------------------ Pre school --------------------------------------- */
.blog-zone {
  padding: 30px 0px 40px;
}

.faq-zone {
  padding: 30px 0px 40px;
}

.have-library-zone {
  padding: 40px 0px;
}

.library-row img {
  width: 100%;
  min-height: 350px;
  object-fit: cover;
  border-radius: 5px;
}
.library-row .row {
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
}

.library-row .row:nth-child(even) {
  flex-direction: row-reverse;
}
.briefcontent {
  margin: 0px 0 20px;
  font-size: 18px;
  font-size: var(--text-xl);
  text-align: justify;
}
.library-row h5 {
  font-size: var(--text-2xl);
  color: var(--color-theme);
  font-weight: 500;
  margin-bottom: 20px;
}

.brief-toolo {
  position: relative;
  padding: 40px 0px 50px;
}

.toolo-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  z-index: 2;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2));
  background-color: #fff;
  border-radius: 5px;
  padding: 7px;
}
.brief-toolo .body-container {
  z-index: 2;
  position: relative;
}

.toolo-pre-pillar-benifits {
  padding-top: 10px;
  max-width: 1200px;
  margin: 0px auto;
}
.pillar-content-box {
  padding: 15px 10px;
  color: var(--color-white);
  text-align: center;
}
.desktop-reverse {
  flex-direction: row-reverse;
}
.first-pillar-box .rotedicon {
  position: absolute;
  right: -230px;
  top: 150px;
}
.third-pillar-box .rotedicon {
  position: absolute;
  left: 91px;
  top: -191px;
}
.second-pillar-box .rotedicon {
  position: absolute;
  left: -150px;
  bottom: -50px;
}
.pillar-content-box h3 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.32);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0px;
}
.pillar-content-box p {
  font-size: 18px;
  margin-bottom: 10px;
}
.pillar-content-box a {
  font-weight: 500;
  font-size: 18px;
  color: var(--color-white);
  text-decoration: none;
  position: relative;
}

.pillar-box {
  margin: 0px auto;
  width: 378px;
  height: 306px;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 0px 40px;
  position: relative;
  justify-content: center;
}

.custonbtnnsn {
  position: relative;
}
.custonbtnnsn svg {
  position: absolute;
  left: calc(50% - 270px);
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.floaticonps {
  width: 60px;
  position: absolute;
  right: -30px;
}
.first-pillar-box {
  background-image: url(../src/assets/images/pre-school/pillar-bg1.png);
}
.second-pillar-box {
  background-image: url(../src/assets/images/pre-school/pillar-bg2.png);
}
.third-pillar-box {
  background-image: url(../src/assets/images/pre-school/pillar-bg3.png);
}

.features-toolo {
  padding: 40px 0px 20px;
  position: relative;
}
.features-toolo .row {
  margin-top: 10px;
  justify-content: center;
}
.fearute-bg {
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.06);
  padding: 18px 20px;
  border-radius: 5px;
  transition: all 0.5s;
  margin-bottom: 30px;
  background-color: #fff;
}
.fearute-bg:hover .icon-bg {
  transform: scale(1.1);
}
.icon-bg {
  background: var(--color-lightchoco);
  height: 60px;
  width: 60px;
  border: 2px solid var(--pre-color2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 44px;
  transition: all 0.5s;
  font-weight: bold;
  font-size: 20px;
}

.fearute-bg h5 {
  text-align: center;
  position: relative;
  font-weight: 600;
  font-size: var(--text-xl);
  color: var(--color-textgray);
  min-height: 44px;
  line-height: 1.5;
}
.fearute-bg h5 b {
  color: var(--color-theme);
}
.fearute-bg h5:before {
  content: '';
  height: 2px;
  width: 70px;
  background: var(--pre-color2);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -17px;
}

.pre-banner {
  background-image: url(../src/assets/images/pre-banner.jpg);
  background-attachment: fixed;
  padding: 50px 0;
}
.pre-banner .pre-banner-text {
  float: left;
  text-align: left;
  max-width: 600px;
}

.why-pre-library ul {
  padding: 0px;
  margin: 0px;
}
.why-pre-library ul li {
  padding-left: 25px;
  position: relative;
  font-size: var(--text-xl);
  margin-bottom: 15px;
  list-style: none;
}
.why-pre-library ul li b {
  color: var(--color-base);
}
.why-pre-library ul li:before {
  background-image: url(../src/assets/images/tick-pre.png);
  width: 13px !important;
  height: 13px;
  max-width: unset !important;
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
  padding: 0;
  width: auto;
  font-size: 0;
  background-color: transparent;
  color: inherit;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-size: 105%;
}

.inner-predescr-school {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #e2e2e2;
  text-align: center;
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
}
.inner-predescr-school img {
  width: auto;
  max-height: 100px;
}
.preschool-address {
  margin-top: 20px;
  padding: 5px;
  width: 100%;
}
.pre-second-section {
  padding: 50px 0;
}
.inner-prescholl-defination {
  padding: 20px 10px 0 20px;
  border: 2px solid var(--pre-color2);
  background-color: var(--pre-color2);
  font-size: var(--text-xl);
  width: calc(100% - 30px);
  height: 100%;
  margin: 0px auto;
  border-radius: 30px;
  display: flex;
  align-items: end;
  min-height: 200px;
  justify-content: center;
  gap: 10px;
}
.inner-prescholl-defination .contentblock {
  width: calc(100% - 155px);
  padding-bottom: 20px;
}
.inner-prescholl-defination .imageblock {
  width: calc(135px - 0px);
  padding-right: 0px;
  text-align: right;
  margin-bottom: -2px;
}

.inner-prescholl-defination .imageblock img {
  max-height: 210px;
}
.slick-slide img {
  display: inline-block;
}

.pre-subheadings.piller-subhead {
  font-size: 34px;
  color: var(--pre-color1);
}
.beforetoolodescr {
  padding: 20px 0 50px;
}
.imp1 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-white);
}
.imp1 b {
  display: block;
  font-size: 50px;
  font-weight: 700;
}

span.year-pre {
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  font-size: 40px !important;
  font-weight: 700;
  padding: 0px 5px;

  line-height: 1;
}
span.equaltosign {
  font-size: 30px;
  display: block;
  font-weight: 700;
}

.imp2 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-base);
}
.imp2 u {
  color: var(--color-white);
  text-decoration: none !important;
}
.imp2 b {
  font-size: 50px;
  font-weight: 700;
}

.imp3 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-white);
}
.imp3 b {
  font-size: 50px;
  font-weight: 700;

  display: block;
}

.imp4 {
  font-size: 23px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-base);
}

.imp4 b {
  font-size: 50px;
  font-weight: 700;

  color: var(--color-white);
  display: block;
}

strong.inheritfont {
  font-weight: 500;
}

.imp5 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-white);
}

.imp5 b {
  font-size: 30px;
  font-weight: 700;

  color: var(--color-white);
  display: block;
}

.imp5 u {
  font-weight: 600;
  text-decoration: none !important;
  font-size: 28px;
}

.imp6 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-base);
}

.imp6 b {
  font-size: 50px;
  font-weight: 700;

  color: var(--color-white);
  display: block;
}

.havethree-pillers {
  margin-top: 15px;
}
.one-piller-insider {
  border-radius: 20px;
  border: 1px solid var(--pre-color1);
  background: var(--color-white);
  text-align: center;
  padding: 10px 30px 30px;
  height: 100%;
}
.onepiller:nth-child(2) .one-piller-insider {
  border-color: var(--pre-color2);
}
.one-piller-insider h4 {
  font-weight: 700;
  font-size: 26px;
  color: var(--color-base);
  max-width: 250px;
  margin: 0px auto;
  position: relative;
  z-index: 2;
}
.one-piller-insider h4 small {
  font-weight: 600;
  font-size: 95%;
}
.after-piller-heading {
  padding: 5px 0 0px;
  position: relative;
  margin-top: -30px;
  z-index: 1;
}
.piller-description {
  font-size: 19px;
  max-width: 300px;
  margin: 0px auto;
  line-height: 1.4;
}

.attactive-pre-test-role {
  padding: 30px 0;
  position: relative;
}
.attactive-pre-test-role ul {
  margin: 0px auto;
  max-width: max-content;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  border: 1px solid #ffc686;
  padding: 10px 15px 10px 70px;
  border-radius: 25px;
  box-shadow: 0px 0px 15px #f3e4d5;
  position: relative;
}
.attactive-pre-test-role ul li {
  list-style: none;
  font-size: 20px;
}
.attactive-pre-test-role ul li svg {
  height: 45px;
  margin-right: 0px;
}
.attactive-pre-test-role ul span {
  position: absolute;
  top: calc(50% - 108px);
  z-index: 2;
  left: -80px;
}
.attactive-pre-test-role ul span svg {
  width: 150px;
  display: inline-block;
}

.preschoolslide .slick-slide:nth-child(odd) .inner-prescholl-defination {
  border: 2px solid var(--pre-color1);
  background-color: var(--pre-color1);
}

.itsfromleft {
  text-align: left;
  max-width: initial;
}
.itsfromleft ul {
  padding: 0;
  margin: 0;
}
.itsfromleft ul li {
  padding-left: 25px;
  position: relative;
  font-size: var(--text-xl);
  margin-bottom: 15px;
  list-style: none;
}

.itsfromleft ul li:before {
  background-image: url(../src/assets/images/tick-pre1.png);
  width: 13px !important;
  height: 13px;
  max-width: unset !important;
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
  padding: 0;
  width: auto;
  font-size: 0;
  background-color: transparent;
  color: inherit;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-size: 105%;
}
.hasroundshare {
  margin-bottom: 25px;
}
.one-piller-insider h4.autowidth {
  max-width: none;
}

.pb0 {
  padding-bottom: 0px;
}
.hasroundshare img {
  border-radius: 25px;
}

.pre-each-testimonials {
  position: relative;
  width: calc(100% - 50px);
  margin: 0px auto;
  height: 100%;
}
.pre-each-testimonials:before {
  background-color: #fdf6f0;
  position: absolute;
  left: -15px;
  top: 10px;
  width: 100%;
  height: calc(100% - 15px);
  z-index: 2;
  content: '';
  border-radius: 20px;
}

.slick-slide:nth-child(odd) .pre-each-testimonials:before {
  background: rgba(248, 226, 166, 0.4);
}
.pre-each-testimonials span {
  position: absolute;
  left: -15px;
  z-index: 4;
  top: -15px;
}
.pre-each-testimonials span svg {
  width: 60px;
  height: 80px;
}
.pre-each-testimonials .whatsays {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  color: var(--color-base);
  border: 1px solid #bf5b4b;
  position: relative;
  z-index: 3;
  padding: 40px 25px 35px 35px;
  border-radius: 20px;
  height: calc(100% - 20px);
}
.pre-each-testimonials .whatsays p {
  margin-bottom: 0px;
  margin-top: 12px;
}
.pre-each-testimonials .whatsays small {
  display: block;
  margin-top: 15px;
  color: #bf5b4b;
  font-weight: 600;
  font-size: 18px;
}

.pre-testimonials .slick-dots {
  bottom: 0px !important;
}

.pre-school-faqs-bg {
  background-color: #faeadc;
  padding: 40px 25px 30px;
  border-radius: 25px;
}

.toolo-powered-library {
  background-color: #edf4f5;
}

.just-icon-after-heading {
  margin: 0px 0px 20px;
  text-align: center;
}

.just-icon-after-heading svg {
  max-height: 50px;
}
.prepageheading {
  color: #08393b;
  display: flex;
  gap: 15px;
  font-size: var(--text-5xl1);
}
.pre-school-list {
  padding: 35px 0;
}
.border-pad15 {
  border: 2px solid var(--pre-color2);
  padding: 15px;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.prefeatures {
  padding: 15px 0 30px;
}
.brief-toolo b {
  color: var(--color-theme);
}

.preschool-address {
  background-color: #fff;
}
.prefeatures .innerbenf {
  text-align: center;
  width: calc(100% - 30px);
  margin: 0px auto;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 250px;
  border: 2px solid var(--pre-color2);
  box-shadow: none !important;
  font-size: var(--text-xl);
}
.prefeatures .innerbenf p {
  font-size: var(--text-xl);
}

.onereward .inside-reward {
  padding: 15px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid var(--pre-color1);
}
.onereward:nth-child(even) .inside-reward {
  border: 1px solid var(--pre-color2);
}

.onereward .imagereward {
  width: 45%;
}
.onereward .text-reward {
  width: calc(55% - 10px);
  color: #2e2e2e;
  font-weight: 700;
  font-size: 20px;
}

.equalts {
  font-size: 130%;
  display: block;
  font-weight: bold;
  color: var(--color-theme);
  line-height: 1;
}

.innewline {
  display: block;
}

.littlebigger {
  font-size: 115%;
}
.brief-toolo p li::marker {
  color: var(--color-theme);
}

.prefeatures .innerbenf b {
  color: var(--color-theme);
}
.prefeatures .innerbenf img {
  display: inline-block !important;
}
.pre-subheadings {
  font-size: var(--text-xl);
  color: var(--color-base);
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.pre-subheadings svg {
  height: 28px;
  display: inline-block;
}
.twolisttt li:nth-child(odd) {
  width: 40%;
  float: left;
}
.twolisttt li:nth-child(even) {
  width: 60%;
  float: left;
}

.allfaqs ul li .sinambans p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.allfaqs ul li .sinambans ul {
  padding-left: 40px;
}
.allfaqs ul li .sinambans li {
  margin-bottom: 7px;
  list-style: disc;
  display: list-item;
}
.allfaqs ul li .sinambans ol {
  margin-top: 15px;
}
.allfaqs ul li .sinambans ol li {
  display: list-item;
  overflow: inherit;
  list-style: unset;
  margin-bottom: 7px;
}

.hassubmenu ul {
  position: absolute;
  top: 100%;
  display: none;
  padding: 8px 0;
  z-index: 99999;
  width: fit-content;
  min-width: 150px;
}

.hassubmenu:hover ul {
  display: block;
}

.hassubmenu ul li {
  list-style: none;
  background-color: #f7f7f7;
  padding: 5px 15px;
  font-size: var(--text-sm);
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
}

.hassubmenu ul li a {
  display: block;
}

.hassubmenu ul li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #e2e2e2;
}

.hassubmenu ul li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #e2e2e2;
}

.hassubmenu ul li:nth-child(4) {
  margin-right: 0;
}

.menulist ul li.hassubmenu::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
  cursor: pointer;
}
.menulist ul li.hassubmenu {
  margin-bottom: 0;
}
.menulist ul ul li:last-child,
.menulist ul li li:nth-last-child(2) {
  display: block;
  transition: 0.3s;
}

.subscriptionbox ul li:first-child:before,
.subscriptionbox ul li:first-child:after {
  display: none !important;
}
.subscriptionbox ul li:nth-child(2):before,
.subscriptionbox ul li:nth-child(2):after {
  display: none !important;
}

.subscriptionbox ul ul li:first-child:before {
  display: block !important;
}
.subscriptionbox ul ul li:nth-child(2):before {
  display: block !important;
}
.myclose-custom-button {
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 20px;
}
.forphonenumber span {
  position: relative;
  padding-left: 8px;
  min-width: 125px;
  display: inline-block;
  text-align: left;
}
.forphonenumber span:before {
  content: ':';
  position: absolute;
  left: 0;
  top: 0;
}
.forphonenumber:hover span {
  color: var(--color-theme);
}

.mycustomselect {
  font-weight: 600;
  color: var(--color-theme1);
  font-size: var(--text-sm);
  background-color: #fbf8f6 !important;
}
.mycustomselect .css-1pahdxg-control {
  box-shadow: none;
}
.mycustomselect .css-yk16xz-control {
  background-color: #fbf8f6 !important;
}
.mycustomselect .css-1pahdxg-control {
  background-color: #fbf8f6 !important;
}
.css-1okebmr-indicatorSeparator {
  margin-top: 0px !important;
  background-color: transparent !important;
}
.css-tlfecz-indicatorContainer svg {
  color: #c46859;
}

.pre-subheadings {
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 10px;
}
.pre-subheadings span {
  display: flex;
  align-items: center;
}

.readmorebutton {
  text-align: center;
  margin-top: 10px;
}
.readmorebutton span {
  cursor: pointer;
  background: #ffffff1c;
  border: 0px solid #ffffff;
  outline: 1px solid #ffffff;
  transition: all 0.3s ease-in-out;
  padding: 5px 15px;
  font-size: 15px;
  letter-spacing: 1px;
}
.readmorebutton:hover span {
  outline-offset: 5px;
}
.padding-top-50 {
  padding-top: 50px;
  overflow: hidden;
  clear: both;
}
.pillar-image img {
  max-height: 120px;
}
.no__margin__left {
  margin-left: 0px !important;
}

.height-0 {
  height: 0px !important;
}
.no__bottom__padding {
  padding-bottom: 0px;
  border-bottom: 0px !important;
}

.search__by__map {
  margin: 0px auto;
  position: relative;
  max-width: 529px;
  margin-top: 45px;
}

.search__by__map button {
  border-radius: 0px 5px 5px 0px !important;
  background-image: linear-gradient(to right, #de6109, #d82c04);
  border-color: #d82c04 !important;
  font-weight: 600;
  min-width: 100px;
}
.search__by__map .location__icons {
  position: absolute;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  cursor: pointer;
  left: 0;
  top: 0;
}
.search__by__map .location__icons:after {
  content: attr(data-text);
  display: block;
  position: absolute;
  top: -35px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 9999;
  color: #fff;
  background-color: #dd4e21;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
  left: -30px;
  letter-spacing: 0.5px;
  transition: 0.2s;
  opacity: 0;
}
.search__by__map .input__map__area:hover .location__icons:after {
  opacity: 1;
  left: 0px;
}
.input__map__area {
  position: relative;
}
.cancle_map__icon {
  position: absolute;
  right: 0px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  height: calc(100% - 6px);
  top: 3px;
  padding: 5px;
  color: #000000;
  line-height: 0;
  display: flex;
  align-items: center;
}

.search__positive__result,
.search__no__result {
  clear: both;
  width: 100%;
  display: block;
}
.community__info {
  max-width: max-content;
  margin: 0px auto;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 50px;
  border-radius: 10px;
}
.no__result__message {
  max-width: max-content;
  margin: 0px auto;
  background-color: #ffffff;
  color: #000000;
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 10px;
}
.community__info h4 {
  font-size: 20px;
  margin-bottom: 7px;
  font-weight: 600;
  color: var(--color-theme);
}
.on__banner__btns {
  margin-top: 10px;
  font-size: 14px;
}

@media only screen and (min-width: 1500px) and (max-width: 5920px) {
  :root {
    --text-xs: 16px;
    --text-sm: 18px;
    --text-md: 20px;
    --text-lg: 20px;
    --text-xl: 22px;
    --text-2xl: 25px;
    --text-3xl: 30px;
    --text-4xl: 32px;
    --text-5xl: 36px;
    --text-6xl: 55px;
    --text-base: 20px;
    --text-menu: 26px;
    --text-menul: 26px;
    --text-cart: 13px;
    --text-wcm: 28px;
    --text-wcms: 34px;
    --text-blgdesc: 20px;
  }

  p.authorname,
  .quickview ul li {
    font-size: var(--text-md);
  }

  .menulist ul li a svg {
    width: 23px;
  }

  .subscriptionbox ul li .price {
    font-size: var(--text-6xl);
  }

  .subscriptionbox ul li .subslength {
    font-size: var(--text-3xl);
  }

  .subscriptionbox ul li .subsfacilities ul li {
    font-size: var(--text-xl);
  }

  .subscriptionbox ul li .innerbox {
    padding: 20px 15px 100px;
  }

  .subscriptionbox ul li .price sub {
    padding-left: 8px;
  }

  .subscriptionbox ul li .price sub:before {
    bottom: 5px;
  }

  .bookcoverrest img {
    max-height: 220px;
    min-height: 220px;
    object-fit: cover;
    width: 100%;
    object-position: top;
  }

  .deachbook img {
    max-height: 220px;
    min-height: 220px;
    object-fit: cover;
    width: 100%;
    object-position: top;
  }

  .subscriptionbox ul li .subsfacilities {
    min-height: 164px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  :root {
    --text-xs: 13px;
    --text-sm: 16px;
    --text-md: 17px;
    --text-lg: 18px;
    --text-xl: 20px;
    --text-2xl: 24px;
    --text-3xl: 26px;
    --text-4xl: 28px;
    --text-5xl: 33px;
    --text-6xl: 50px;
    --text-base: 20px;
    --text-menu: 20px;
    --text-menul: 24px;
    --text-cart: 12px;
    --text-wcm: 26px;
    --text-wcms: 30px;
    --text-blgdesc: 15px;
  }

  .subscriptionbox ul li .price {
    font-size: var(--text-6xl);
  }

  .subscriptionbox ul li .subslength {
    font-size: var(--text-3xl);
  }

  .subscriptionbox ul li .subsfacilities ul li {
    font-size: var(--text-xl);
  }

  .bookself-container {
    width: 90%;
  }

  .body-container {
    width: 90%;
  }

  .subscriptionbox ul li .price sub {
    padding-left: 7px;
  }

  .dashiconbox svg {
    height: 40px;
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .menulist ul.desktop-menulist {
    display: none;
    background-color: #f9f9f9;
    padding: 15px 25px 50px 15px;
    border-bottom: 3px solid #f87026;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .mobilemenu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .mobilelogo {
    color: var(--color-theme);
  }
  .mobilelogo img {
    max-height: 40px;
  }
  .height-0 {
    height: 56px !important;
  }
  .no__bottom__padding {
    padding-bottom: 50px !important;
    border-bottom: 1px solid #e2e2e2 !important;
  }

  .mobilelogo a {
    color: var(--color-theme);
  }
  .mobilecart {
    width: 30px;
    margin-left: 15px;
    position: relative;
  }
  .mobilecart sup {
    top: -7px;
    position: absolute;
    font-weight: bold;
    background-color: var(--color-theme);
    width: 20px;
    height: 20px;
    text-align: center;
    right: -5px;
    border-radius: 100%;
    line-height: 20px;
    color: var(--color-white);
  }
  .mobilerighertst {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .contact-header-iconss {
    margin-left: 10px;
  }
  .contact-header-iconss img {
    max-height: 24px;
  }
  .body-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .bookself-container {
    width: 100%;
  }
  .menulist ul.desktop-menulist.mobilemenu-visible {
    display: block;
  }
  .leftmenutab.yesopened {
    position: relative;
    z-index: 9;
    position: fixed;
    right: 20px;
    top: 20px;
  }
  .menulist ul li {
    line-height: 1;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  .menulist ul li.loginlistts {
    margin-left: 0;
    margin-right: 0;
  }
  .mob-profile-img {
    margin-right: 15px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid #e2e2e2;
    padding: 2px;
    box-shadow: 0 0 10px #e2e2e2;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .dashcontentrest {
    width: 100%;
    padding-left: 15px;
  }
  .dashboardbox .dashsidebar {
    display: none !important;
  }
  .sidebarinner {
    width: 100%;
    display: none;
  }
  .sidebarinner.mobilemenu-visible {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    overflow-y: auto;
  }
  .bookself .dashsidebar {
    display: block;
  }
  .batsdash.yesopened {
    position: fixed;
    z-index: 99999;
    right: 20px;
    top: 10px;
  }
  .dasboardmenuonlymob {
    display: block;
  }
  .menulist ul li a {
    display: block;
  }
  .menulist ul li:last-child,
  .menulist ul li:nth-last-child(2) {
    display: block;
    transition: 0.3s;
  }
  .top-logo-bar {
    display: none;
  }
  .herotext h1 {
    font-size: 35px;
  }
  .herotext .mt-100 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .pre-banner {
    background-attachment: inherit;
    background-position: center;
    min-height: auto;
  }
  .menulist {
    padding: 5px 0 5px;
  }
  .healthNotify {
    display: none !important;
  }
  .scrollTop {
    display: none !important;
  }
  .wp-contact {
    bottom: 20px !important;
  }

  .homepagemstborrowed .slick-prev,
  .homepagemstborrowed .slick-next {
    display: none !important;
  }

  .infosliderssss .slick-prev,
  .infosliderssss .slick-next {
    display: none !important;
  }
  .twolisttt li:nth-child(odd),
  .twolisttt li:nth-child(even) {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  :root {
    --text-xs: 14px;
    --text-sm: 16px;
    --text-md: 18px;
    --text-lg: 20px;
    --text-xl: 22px;
    --text-2xl: 24px;
    --text-3xl: 26px;
    --text-4xl: 28px;
    --text-5xl: 32px;
    --text-6xl: 40px;
    --text-base: 18px;
    --text-menu: 22px;
    --text-menul: 22px;
    --text-cart: 12px;
    --text-wcm: 24px;
    --text-wcms: 28px;
    --text-blgdesc: 15px;
  }

  footer .topfootermenu h4 {
    margin-top: 10px;
  }

  .bookself-container {
    width: 100%;
  }

  .body-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .slick-prev,
  .slick-next {
    width: 30px !important;
    height: 30px !important;
    z-index: 99;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 10px #5c5c5c;
    border-radius: 5px;
    line-height: 41px;
    opacity: 1 !important;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5 !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px !important;
    font-weight: 700;
    font-family: inherit !important;
    opacity: 1 !important;
    color: #000000 !important;
  }

  .slick-prev {
    left: -7px !important;
  }

  .slick-next {
    right: -7px !important;
  }

  .subscriptionbox ul li {
    width: 50%;
    margin-bottom: 10px;
  }

  .subscriptionbox ul li:last-child:before {
    top: -9px;
    z-index: 9;
  }

  .subscriptionbox ul li:last-child:after {
    top: 33px;
  }

  .rlc-box .pr-0 {
    padding-right: 15px !important;
  }

  .rlc-box .pl-0 {
    padding-left: 15px !important;
  }

  .withcocobg {
    border-left: 0;
  }

  .hignlightedbox .col-md-4 {
    margin-bottom: 10px;
  }

  .sidebarinner {
    width: 100%;
    display: none;
  }

  .dashsidebar {
    width: 100%;
  }

  .dashcontentrest {
    width: 100%;
    padding-left: 15px;
  }

  .childfbox {
    flex-wrap: wrap;
  }

  .eachchild {
    width: calc(100% - 6px);
    margin-bottom: 5px;
  }

  .partbyborroewed .slide {
    width: calc(33.3% - 4px);
  }

  .menulist ul.desktop-menulist {
    display: none;
    background-color: #f9f9f9;
    padding: 15px 25px 50px 15px;
    border-bottom: 3px solid #f87026;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .menulist ul li.hassubmenu {
    margin-bottom: 0;
  }

  .menulist {
    padding: 5px 0 0;
    display: block;
  }

  .menulist ul.desktop-menulist.mobilemenu-visible {
    display: block;
  }

  .menulist ul li {
    line-height: 1;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  .leftmenutab.yesopened {
    position: relative;
    z-index: 9;
    position: fixed;
    right: 10px;
    top: 20px;
  }

  .bookself.sticky header.menubararea {
    background-color: #f9f9f9;
  }

  .onlydesktop {
    display: none;
  }

  .onlymobile {
    display: block;
  }

  .dashcontentinner {
    border-left: 0;
  }

  .dasboardmenu {
    width: auto;
    display: flex;
    margin-left: 15px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 17px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #bcb8b8;
    padding: 5px;
    color: #888;
    float: right;
    margin-right: 20px;
    border-radius: 5px;
    font-size: 0;
    opacity: 0;
    display: none;
  }

  .sidebarinner.mobilemenu-visible {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    overflow-y: auto;
  }

  .batsdash.yesopened {
    position: fixed;
    z-index: 99999;
    right: 10px;
    top: 10px;
  }

  .contsbox {
    width: calc(100% - 120px);
  }

  .vissonbox {
    margin-left: 20px;
  }

  .missionbox {
    margin-right: 20px;
  }

  .missionbox1 {
    margin-right: 20px;
  }

  .vissonbox1 {
    margin-left: 20px;
  }

  .arcshbox {
    width: calc(100% - 220px);
  }

  .aftersearchlist .slide {
    width: 33.33%;
  }

  .aboutlftconts,
  .aboutmdlimg,
  .aboutrgtconts {
    width: 100%;
    text-align: center;
  }

  .mobilerighertst {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .mobilecart {
    width: 30px;
    margin-left: 15px;
    position: relative;
  }

  .mobilecart sup {
    top: -7px;
    position: absolute;
    font-weight: bold;
    background-color: var(--color-theme);
    width: 20px;
    height: 20px;
    text-align: center;
    right: -5px;
    border-radius: 100%;
    line-height: 20px;
    color: var(--color-white);
  }

  .mobilecart a.active svg g {
    fill: var(--color-theme);
  }

  .imageblog {
    width: 100%;
    clear: both;
    float: none;
    margin: 0 auto;
    padding-right: 0;
    max-width: 500px;
  }

  .mob-profile-img {
    margin-right: 15px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid #e2e2e2;
    padding: 2px;
    box-shadow: 0 0 10px #e2e2e2;
    display: flex;
    align-items: center;
  }

  .dasboardmenuonlymob {
    display: block;
  }

  .dashboardbox .dashsidebar {
    display: none !important;
  }

  .bookself .dashsidebar {
    display: block;
  }

  .recomandersaid {
    width: 100%;
    margin-left: 0;
    padding-left: 15px;
  }

  .reconderinfo {
    margin: 0 auto;
  }

  .recomandersaid:before {
    right: -1px;
    top: -34px;
    left: auto;
  }

  .bookleftimagewact {
    position: relative;
    margin: 0 auto;
    margin-top: -70px;
  }

  .bookrightinted {
    padding-left: 0;
    padding-top: 40px;
  }

  .bookrightinted h4 {
    position: relative;
    top: 0;
    text-align: center;
  }

  .bookrightinted ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bookrightinted ul li {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }

  .socialbuttonlogin a {
    display: block;
  }

  .alreadyuser {
    flex-wrap: wrap;
  }

  .alreadyuser span {
    width: 100%;
    display: block;
    margin-top: 5px;
  }

  .menulist ul li.loginlistts {
    margin-left: 0;
    margin-right: 0;
  }

  .abmpage .commonambs .infocomnamb {
    width: 100%;
  }

  .abmpage .commonambs .imgcomamb {
    width: 100%;
    text-align: center;
    margin-top: 7px;
    padding-left: 0;
  }

  .ambspage .arcshbox {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  .onlymobiledisplaymsg {
    display: block;
  }
  .highlightsavebox .rightimagebx {
    display: none;
  }
  .highlightsavebox .leftbox {
    width: 100px;
  }
  .leftbox .mascotimg {
    width: 70px;
    margin-right: 5px;
  }
  .highlightsavebox .contentblock {
    width: calc(100% - 100px);
    padding: 0px 30px 0 15px;
  }
  .highlightsavebox .clodethis {
    right: 5px;
    top: 5px;
  }
  .onestepbox {
    margin-bottom: 20px;
    height: auto;
  }
  .about_block h3 {
    font-size: 30px;
    line-height: 1.3;
  }
  .toolo-data-gist .col-md-4 .inner-gist-data {
    margin-top: 30px;
    height: auto;
  }
  .toolo-data-gist .col-md-4:first-child .inner-gist-data {
    margin-top: 0px;
  }
  .cityname {
    width: 50%;
  }
  .whatsay {
    font-size: var(--text-md);
  }
  .citylist {
    padding: 20px 0 !important;
  }
  .citylist .pt-40 {
    padding-top: 25px;
  }
  .newsarticle .col-md-4 {
    margin: 10px 0;
  }
  .aboutimg {
    margin: 10px 0;
  }
  .mobile-rev {
    flex-direction: column-reverse;
  }
  .brief-toolo .col-md-6 {
    margin: 10px 0;
  }
  .pre-school-list .col-md-4 {
    margin: 11px 0;
  }
  .prepageheading {
    font-size: 18px;
    line-height: 1.4;
  }
  .inner-prescholl-defination .contentblock {
    padding-bottom: 5px;
  }
  .prepageheading svg {
    display: none;
  }
  .inner-prescholl-defination {
    flex-wrap: wrap;
  }
  .inner-prescholl-defination .contentblock {
    width: 100%;
  }
  .inner-prescholl-defination .imageblock {
    width: 100%;
    text-align: center;
  }
  .imp1 {
    font-size: 22px;
  }
  .imp1 b {
    font-size: 40px;
  }

  span.year-pre {
    font-size: 30px !important;
  }
  span.equaltosign {
    font-size: 24px;
  }

  .imp2 {
    font-size: 22px;
  }

  .imp2 b {
    font-size: 40px;
  }

  .imp3 {
    font-size: 22px;
  }
  .imp3 b {
    font-size: 40px;
  }

  .imp4 {
    font-size: 22px;
  }

  .imp4 b {
    font-size: 40px;
  }

  .imp5 {
    font-size: 22px;
  }

  .imp5 b {
    font-size: 28px;
  }

  .imp5 u {
    font-size: 24px;
  }

  .imp6 {
    font-size: 22px;
  }

  .imp6 b {
    font-size: 40px;
  }
  .inner-prescholl-defination .imageblock img {
    max-height: 150px;
  }

  .floaticonps {
    top: -24px;
    left: calc(50% - 30px);
  }
  .pillar-content-box h3 {
    margin-top: 15px;
    text-align: center;
  }
  .pillar-content-box p {
    text-align: center;
  }
  .pillar-box {
    justify-content: center;
  }
  .attactive-pre-test-role {
    padding: 0px 0px 10px;
  }
  .attactive-pre-test-role ul li {
    font-size: 16px;
  }
  .attactive-pre-test-role ul li svg {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }
  .pillar-box {
    margin-bottom: 40px;
    width: 100%;
    height: auto;
    border-radius: 20px;
    padding: 30px 0px 60px 0px;
  }
  .first-pillar-box .rotedicon,
  .second-pillar-box .rotedicon,
  .third-pillar-box .rotedicon {
    display: none;
  }
  .first-pillar-box {
    background-image: none;
    background-color: var(--pre-color2);
  }
  .second-pillar-box {
    background-image: none;
    background-color: #bf5b4b;
  }
  .third-pillar-box {
    background-image: none;
    background-color: var(--pre-color1);
    margin-bottom: 0px;
  }
  .attactive-pre-test-role ul span {
    display: none;
  }
  .attactive-pre-test-role ul {
    padding-left: 15px;
    justify-content: center;
  }
  .pre-each-testimonials {
    width: calc(100% - 20px);
  }
  .pre-subheadings {
    font-size: 14px;
  }
  .pre-subheadings svg {
    height: 18px;
  }
  .prebuttons {
    padding: 9px 20px;
  }
  .pre-subheadings.piller-subhead {
    font-size: 18px;
  }
  .one-piller-insider h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --text-xs: 13px;
    --text-sm: 15px;
    --text-md: 16px;
    --text-lg: 18px;
    --text-xl: 20px;
    --text-2xl: 22px;
    --text-3xl: 22px;
    --text-4xl: 24px;
    --text-5xl: 32px;
    --text-6xl: 40px;
    --text-base: 18px;
    --text-menu: 22px;
    --text-menul: 22px;
    --text-cart: 12px;
    --text-wcm: 22px;
    --text-wcms: 24px;
  }

  .partbyborroewed .slide {
    width: calc(50% - 4px);
  }

  .subscriptionbox ul li {
    width: 100%;
  }

  .subscriptionbox ul li .subsfacilities {
    min-height: fit-content;
  }

  .one-third {
    width: calc(100% - 10px);
    float: none;
  }

  .aftersearchlist .slide {
    width: 50%;
  }

  .contsbox,
  .arcshbox {
    width: 100%;
  }

  .samemsvimg,
  .profileabout {
    width: 100%;
    text-align: center;
  }

  .samemsvimg img {
    max-height: 120px;
  }

  .profileabout img {
    max-height: 150px;
  }

  .innermsvbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .vissonbox {
    margin-left: 0;
  }

  .missionbox {
    margin-right: 0;
  }

  .missionbox1 {
    margin-right: 0;
  }

  .vissonbox1 {
    margin-left: 0;
  }

  .inneraboutus {
    padding: 15px;
  }

  .missionvisonbox:nth-child(odd) .innermsvbox {
    flex-direction: column-reverse;
  }

  .bookthumbzone {
    width: 100%;
  }

  .bookimptdzone {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
  }

  .hassubmenu ul {
    position: relative;
    opacity: 1;
    display: block;
    margin: initial;
    width: 100%;
  }

  .hassubmenu ul li {
    border: none;
    background-color: transparent;
    margin-bottom: 10px;
  }

  .menulist ul li.hassubmenu::after {
    display: none;
  }

  .copyrighttxt {
    width: 100%;
    text-align: center;
    float: none;
  }

  .socialmediaf {
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 5px;
  }

  .comingsoon span {
    line-height: 1;
    font-size: 50px;
  }

  .childdp {
    width: 70px;
  }

  .childinfo {
    width: calc(100% - 70px);
  }

  .skipfornow {
    margin-left: 0;
  }

  .boollistbased .wishlist {
    margin-left: 3px;
  }

  .hassubmenu ul li:first-child,
  .hassubmenu ul li:last-child {
    border: none;
  }

  .imageblog {
    max-width: max-content;
  }
  .upcomeimglft {
    width: 100%;
    margin-right: 0px;
  }
  .upcomeinforight {
    width: 100%;
    margin-top: 15px;
  }
  .forphonenumber span {
    min-width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .boollistbased .wishlist {
    margin-left: 0;
  }
}

.modal-body {
  white-space: pre-line;
}
.redeemed-badge {
  cursor: pointer;
  background-color: var(--subscription-threebtn);
  padding: 2px 8px;
  color: var(--color-white);
  font-size: 12px;
  border-radius: 4px;
  margin-left: 5px;
}

h2.sectionheader {
  margin-bottom: 0px;
}

.ifexternallink {
  font-size: var(--text-sm);
  font-weight: 600;
  margin-bottom: 5px;
}
.ifexternallink a {
  display: inline-block;
  color: var(--color-theme);
}

.wp-contact {
  position: fixed;
  text-align: right;
  bottom: calc(50% - 7px);
  right: 10px;
  z-index: 9999;
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 15px 0 transparent;
}
.wp-contact:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px 0 #969696;
}
.mt-25 {
  margin-top: 25px;
}
.noclick {
  cursor: not-allowed;
  pointer-events: none;
}
.modal-open .modal {
  z-index: 10000;
}
.modal-backdrop.show {
  z-index: 9999;
}
.advDataModal .closeme {
  padding: 0px;
  border: 0px !important;
  outline: none !important;
  background-color: transparent !important;
  font-size: 24px;
  box-shadow: none !important;
  color: var(--color-theme) !important;
  margin-top: -15px;
  font-weight: 600;
}

.myspecial {
  min-width: 600px;
  position: fixed;
  right: 0;
}
#desktopsearh {
  display: flex;
}
#mobilesearch {
  display: none;
}
@media only screen and (max-width: 700px) {
  #desktopsearh {
    display: none;
  }
  #mobilesearch {
    display: block;
  }
}

@media only screen and (max-width: 620px) {
  .myspecial {
    min-width: auto !important;
  }
  .leftboxx {
    width: calc(100% - 120px);
  }
  .rightboxx {
    width: 120px;
  }
}

.inbottom {
  display: block;
  font-style: italic;
}

.quick-search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  color: #4b4b4b;
  background-color: #fff;
}

.quick-search-item span {
  position: relative;
}

.quick-search-item .type {
  font-size: 12px;
  font-weight: 600;
  background-color: #f87026;
  color: #ffffff;
  padding: 2px 5px;
  text-align: center;
  width: 50px;
  justify-content: center;
  border-radius: 5px;
}

.overlay-container {
  position: relative;
  z-index: 9999;
}

.overlay-content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  padding: 0 2px;
}

.overlay-content .wrapper {
  border-radius: 1px !important;
}

.overlay-content .select-search-options {
  list-style: none;
  margin: 0;
  padding-left: 0%;
  /* box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1); */
}

.overlay-content .select-search-value input {
  width: 100%;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 40px;
  height: auto;
  min-height: 40px;
  color: var(--color-theme1);
  background-color: #fbf8f6;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid #bcb0b0;
  border-radius: 4px;
  font-size: var(--text-sm);
}

.filter-search-auto-complete .toolo-select__indicators {
  padding-right: 10px;
}

.filter-search-auto-complete .toolo-select__menu {
  z-index: 99999;
}

#desktopsearh .toolo-select__menu {
  z-index: 99999;
}

#mobilesearch .toolo-select__menu {
  z-index: 99999;
}

.async-search-no-result {
  padding: 10px 15px;
  color: #4b4b4b;
  background-color: #fff;
}

.pre-ribbon-header {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 18px;
}

.pre-ribbon-header .toolo-nominates {
  position: relative;
  width: 35px;
  display: inline-block;
  margin-right: 20px;
  z-index: 0;
}

#list-of-preschoolslsl .col-md-4 {
  margin-bottom: 15px;
}

.onlyforwishlist {
  display: none;
}
.forwishlist .onlyforwishlist {
  display: block;
}

.visibleit {
  position: absolute;
  right: 3px;
  top: 2px;
  background-color: #fbf8f6;
  font-size: 23px;
  padding: 0px 10px;
  cursor: pointer;
}

.secondary__filter__buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5px 0 10px;
}

.reset__secondary {
  background-color: #e5e5e5;
  padding: 5px 25px;
  border-radius: 5px;
  margin: 2px 0 0;
  min-width: 150px;
}

.secondary__search__area {
  margin: 0px auto;
  max-width: 600px;
  width: 100%;
  padding: 10px 0;
  z-index: 99;
  position: relative;
}

.mb10 {
  margin-bottom: 10px;
}

.min-150 {
  min-height: 150px;
  text-align: center;
}

.justify__center__padding20 {
  justify-content: center;
  padding-top: 20px;
}

.justify__center__nopadding {
  justify-content: center;
  padding-top: 0px;
}

.location-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.distance-message {
  font-size: 1.1em;
  color: #4a4a4a;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.no__result__message h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.no__result__message p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.rlc-submit {
  transition: all 0.3s ease;
}

.rlc-submit:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.selected__info__block {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.location__based__plan {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  z-index: 999;
}
.advance__search__enable {
  padding-top: 25px;
  font-size: 16px;
  font-weight: 600;
  color: #282828;
  cursor: pointer;
}

.subscription__non__display {
  border: 2px solid #e2e2e2;
  text-align: center;
}

.buttons__on__banner {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 25px;
  flex-wrap: wrap;
}

.sub__s__community {
  padding-bottom: 10px;
  text-align: center;
  padding-top: 15px;
  font-weight: 700;
  font-size: 24px;
}

.wp__group__block {
  clear: both;
  width: 100%;
  margin: 10px 5px;
  padding: 5px 10px;
  border: 1px solid #50af36;
  color: #000000;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  font-weight: 500;
}
.wp__group__block a {
  color: #070707;
  /* width: calc(100% - 35px); */
}

.wp__group__block svg {
  height: 25px;
  width: 25px;
  fill: #50af36;
}

@media only screen and (max-width: 529px) {
  .search__by__map button {
    width: 100%;
    margin-top: 5px;
    border-radius: 5px !important;
  }
  .input__map__area {
    width: 100%;
  }
  .cancle_map__icon {
    right: 1px;
  }
}

.preview__library__block .secondary__search__area {
  display: none;
}
